<template>
  <div class="w-100 pa-5" v-if="anket.status">
    <p><b>Anket Durumu:</b> {{ this.status }}</p>
    <p>
      <b>Anket Tarihi:</b>
      {{ moment(anket.createdAt).add(3, "hours").format("DD-MM-YYYY HH:mm") }}
    </p>
    <p>
      <b>Yorum:</b>
      {{ anket.content || "Yorum yapılmamış." }}
    </p>

    <p v-if="anket.image">
      <b>Görsel:</b> <br />
      <a
        :href="'https://cdn.renticar.com/comment/' + anket.image"
        target="_blank"
      >
        <CImg
          class="mt-2"
          rounded
          :src="'https://cdn.renticar.com/comment/' + anket.image"
          height="250"
        />
      </a>
    </p>
    <CListGroup v-for="(vote, index) in anket.voteList" :key="vote.index">
      <CListGroupItem
        :class="Number(vote.vote) > 3 ? 'success' : 'error'"
        style="border-radius: 5px; margin-top: 10px"
      >
        <p class="mb-1"><b>Soru: </b> {{ vote.question }}</p>

        <p class="mb-1"><b>Puan: </b> {{ vote.vote }}</p>
      </CListGroupItem>
    </CListGroup>
  </div>
  <div class="w-100" v-else>
    <h4>Anket bulunmuyor.</h4>
  </div>
</template>
<script>
import Vue from "vue";
import axios from "axios";
import moment from "moment";

export default {
  name: "Comment",
  props: {
    _id: {
      type: [String, Number],
      required: true,
    },
    slug: {
      type: String,
      required: true,
    },
    reservationCode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      visibleLiveDemo: false,
      anket: [],
      content: "",
      status: "",
    };
  },

  created() {
    this.getComments();
  },
  methods: {
    async getComments() {
      try {
        let comments = await axios.get(
          process.env.VUE_APP_API_URL + "admin/comment/" + this.reservationCode,
          {
            withCredentials: true,
          }
        );
        this.anket = comments.data;
        this.surveyStatus();
      } catch (error) {}
    },

    surveyStatus() {
      if (this.anket.status == "pending") {
        this.status = "Onay Bekliyor";
      } else if (this.anket.status == "approved" || "active") {
        this.status = "Onaylanmış";
      } else {
        return (this.status = "Hata");
      }
    },

    itemColor(vote) {
      if (Number(vote.vote) < 3) {
        return "background-color:#d9534f";
      } else {
        return "background-color:#5cb85c";
      }
    },
  },
};
</script>
