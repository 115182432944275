var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.anket.status
    ? _c(
        "div",
        { staticClass: "w-100 pa-5" },
        [
          _c("p", [
            _c("b", [_vm._v("Anket Durumu:")]),
            _vm._v(" " + _vm._s(this.status)),
          ]),
          _c("p", [
            _c("b", [_vm._v("Anket Tarihi:")]),
            _vm._v(
              " " +
                _vm._s(
                  _vm
                    .moment(_vm.anket.createdAt)
                    .add(3, "hours")
                    .format("DD-MM-YYYY HH:mm")
                ) +
                " "
            ),
          ]),
          _c("p", [
            _c("b", [_vm._v("Yorum:")]),
            _vm._v(
              " " + _vm._s(_vm.anket.content || "Yorum yapılmamış.") + " "
            ),
          ]),
          _vm.anket.image
            ? _c("p", [
                _c("b", [_vm._v("Görsel:")]),
                _vm._v(" "),
                _c("br"),
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "https://cdn.renticar.com/comment/" + _vm.anket.image,
                      target: "_blank",
                    },
                  },
                  [
                    _c("CImg", {
                      staticClass: "mt-2",
                      attrs: {
                        rounded: "",
                        src:
                          "https://cdn.renticar.com/comment/" + _vm.anket.image,
                        height: "250",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._l(_vm.anket.voteList, function (vote, index) {
            return _c(
              "CListGroup",
              { key: vote.index },
              [
                _c(
                  "CListGroupItem",
                  {
                    class: Number(vote.vote) > 3 ? "success" : "error",
                    staticStyle: {
                      "border-radius": "5px",
                      "margin-top": "10px",
                    },
                  },
                  [
                    _c("p", { staticClass: "mb-1" }, [
                      _c("b", [_vm._v("Soru: ")]),
                      _vm._v(" " + _vm._s(vote.question)),
                    ]),
                    _c("p", { staticClass: "mb-1" }, [
                      _c("b", [_vm._v("Puan: ")]),
                      _vm._v(" " + _vm._s(vote.vote)),
                    ]),
                  ]
                ),
              ],
              1
            )
          }),
        ],
        2
      )
    : _c("div", { staticClass: "w-100" }, [
        _c("h4", [_vm._v("Anket bulunmuyor.")]),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }