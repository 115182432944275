<template>
  <div>
    <div v-if="pageLoading" fluid>
      <CAlert
        v-if="reservationData.spendingObjection"
        class="mt-3"
        color="danger">
        {{ $t("description.spending_objection") }}
      </CAlert>
      <CAlert
        class="mt-3"
        v-if="
          reservationData.paymentType == 'noneSecurePos' &&
          !isPay &&
          !reservationData.vendorReservationCode
        "
        color="warning">
        <strong> {{ $t("caution") }}</strong>
        {{ $t("description.spending_desc") }}
      </CAlert>

      <v-row>
        <v-col cols="12" lg="5" md="6" sm="12">
          <!-- ----REZERVASYON DETAY BAŞLANGIÇ ----REZERVATION DETAIL START ------->
          <v-card elevation="10" class="mb-5">
            <v-toolbar
              height="40"
              dense
              dark
              color="grey darken-1"
              elevation="0">
              <v-toolbar-title style="font-size: 14px"
                ><strong> {{ $t("reservation") }} </strong> #{{
                  reservationData.reservationCode
                }}</v-toolbar-title
              >
              <v-spacer></v-spacer>
              {{ reservationData.searchPrice.car.brand }}
              {{ reservationData.searchPrice.car.model }}
            </v-toolbar>

            <v-row>
              <v-col
                cols="12"
                md="6"
                sm="12"
                class="align-items-center justify-start">
                <v-img
                  height="70"
                  contain
                  :src="ENV_URL_CARS + reservationData.searchPrice.car.image" />
              </v-col>
              <v-col
                cols="12"
                md="6"
                sm="12"
                class="p-3 d-flex align-items-center">
                <v-img
                  :src="
                    ENV_URL_VENDOR + reservationData.searchPrice.vendor.logo
                  "
                  max-height="70"
                  contain />
              </v-col>

              <v-col sm="12" md="12" class="ml-1">
                <v-row class="align-items-center justify-content-center pa-0">
                  <v-col cols="6" sm="6" md="3" class="pa-0">
                    <strong>
                      {{ $t("class") }}
                    </strong>
                    <p>
                      {{
                        $store.state.ceviri[
                          reservationData.searchPrice.car.class
                        ]
                      }}
                    </p>
                  </v-col>
                  <v-col cols="6" sm="6" md="3" class="p-0">
                    <strong>
                      {{ $t("gear") }}
                    </strong>
                    <p>
                      {{
                        $store.state.ceviri[
                          reservationData.searchPrice.car.transmission
                        ]
                      }}
                    </p>
                  </v-col>
                  <v-col cols="6" sm="6" md="3" class="p-0">
                    <strong>
                      {{ $t("fuel") }}
                    </strong>
                    <p>
                      {{
                        $store.state.ceviri[
                          reservationData.searchPrice.car.fuel
                        ]
                      }}
                    </p>
                  </v-col>
                  <v-col cols="6" sm="6" md="3" class="p-0">
                    <strong>
                      {{ $t("seat") }}
                    </strong>
                    <p>
                      {{ reservationData.searchPrice.car.seat }}
                    </p>
                  </v-col>

                  <v-col cols="6" sm="6" md="3" class="p-0">
                    <strong>
                      {{ $t("km_limit") }}
                    </strong>
                    <p>
                      {{
                        reservationData.searchPrice.rules.dailyRangeLimit === 0
                          ? "Sınırsız"
                          : reservationData.searchPrice.rules.totalRangeLimit +
                            " KM"
                      }}
                    </p>
                  </v-col>
                  <v-col cols="6" sm="6" md="3" class="p-0">
                    <strong>
                      {{ $t("age") }}
                    </strong>
                    <p>
                      +
                      {{ reservationData.searchPrice.rules.driverAge }}
                    </p>
                  </v-col>
                  <v-col cols="6" sm="6" md="3" class="p-0">
                    <strong>
                      {{ $t("driving_license") }}
                    </strong>
                    <p>
                      {{ reservationData.searchPrice.rules.licenseYears }}
                      {{ $t("year") }}
                    </p>
                  </v-col>
                  <v-col cols="6" sm="6" md="3" class="p-0">
                    <strong>
                      {{ $t("fuel") }}
                    </strong>
                    <p>
                      {{
                        $store.state.ceviri[
                          reservationData.searchPrice.car.fuelPolicy
                        ]
                      }}
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="m-0"></v-divider>
            <v-row>
              <v-col col="10">
                <v-row>
                  <v-col col="4" class="pa-0 ma-0 pl-1">
                    <h5 style="font-weight: 900">
                      <v-icon title="Alış Lokasyonu"> mdi-arrow-up-box </v-icon>
                      {{ reservationData.carDeliveryReturn.delivery.name }}
                    </h5>
                    <div class="d-flex align-items-center mt-3">
                      <font-awesome-icon
                        :icon="['fas', 'calendar-alt']"
                        size="lg"
                        class="mr-2" />
                      <p class="h6 m-0">
                        {{
                          reservationData.carDeliveryReturn.delivery.datetime
                            | moment("Do MMMM YYYY, dddd HH:mm")
                        }}
                      </p>
                    </div>

                    <div class="d-flex align-items-center mt-3">
                      <font-awesome-icon
                        :icon="['fas', 'info-circle']"
                        size="lg"
                        class="mr-2" />
                      <p class="h6 m-0">
                        {{
                          $store.state.ceviri[
                            reservationData.searchPrice.office.deliveryType
                          ]
                        }}
                      </p>
                    </div>
                    <p class="m-0 mt-1 small">
                      {{ reservationData.searchPrice.office.address }}
                    </p>
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col col="6" class="pa-0 ma-0 pl-5">
                    <h5 style="font-weight: 900">
                      <v-icon title="İade Lokasyonu">
                        mdi-arrow-down-box
                      </v-icon>
                      {{ reservationData.carDeliveryReturn.return.name }}
                    </h5>
                    <div class="d-flex align-items-center mt-3">
                      <font-awesome-icon
                        :icon="['fas', 'calendar-alt']"
                        size="lg"
                        class="mr-2" />
                      <p
                        class="h6 m-0"
                        v-if="
                          reservationData.status !== `confirmedEarlyReturn`
                        ">
                        {{
                          reservationData.carDeliveryReturn.return.datetime
                            | moment("Do MMMM YYYY, dddd HH:mm")
                        }}
                      </p>
                      <p
                        class="h6 m-0 text-decoration-line-through"
                        v-if="reservationData.status == `confirmedEarlyReturn`">
                        {{
                          reservationData.earlyReturn?.oldDate
                            | moment("Do MMMM YYYY, dddd HH:mm")
                        }}
                      </p>
                    </div>
                    <div
                      v-if="EarlyReturnCardInfo.CardShow == true"
                      class="d-flex align-items-center mt-3">
                      <font-awesome-icon
                        :icon="['fas', 'calendar-alt']"
                        size="lg"
                        class="mr-2 red--text" />
                      <p class="h6 m-0 red--text font-weight-bold">
                        {{
                          EarlyReturnCardInfo.earlyReturnDate
                            | moment("Do MMMM YYYY, dddd HH:mm")
                        }}
                      </p>
                    </div>

                    <div class="d-flex align-items-center mt-3">
                      <font-awesome-icon
                        :icon="['fas', 'info-circle']"
                        size="lg"
                        class="mr-2" />
                      <p class="h6 m-0">
                        {{
                          $store.state.ceviri[
                            reservationData.searchPrice.returnOffice
                              .deliveryType
                          ]
                        }}
                      </p>
                    </div>
                    <p class="m-0 mt-1 small">
                      {{ reservationData.searchPrice.returnOffice.address }}
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="m-0"></v-divider>

            <div
              v-if="reservationData.searchPrice.campaignData != null"
              class="pt-2 ml-3 mr-3">
              <div>
                <strong>{{ $t("campaign") }} :</strong>
                {{
                  reservationData.searchPrice.campaignData.info.name.tr
                    ? reservationData.searchPrice.campaignData.info.name.tr
                    : reservationData.searchPrice.campaignData.info.name || ""
                }}
              </div>
              <div>
                <div class="pr-3">
                  <strong>{{ $t("discount") }} :</strong>
                  %{{
                    reservationData.searchPrice.campaignData.discountRate ||
                    reservationData.searchPrice.campaignData.discountAmount
                  }}
                </div>
              </div>
            </div>
            <v-divider
              class="m-0"
              v-if="
                reservationData.searchPrice.campaignData != null
              "></v-divider>
            <v-simple-table dense>
              <tbody>
                <tr>
                  <td>
                    <strong>{{ $t("daily_rental_fee") }}</strong>
                    <span
                      v-if="
                        reservationData?.prices?.dailyRentalPrice.TRY >
                        reservationData?.prices?.retailDailyRentalPrice?.TRY
                      "
                      ><br /><small>{{
                        $t("retailDailyRentalPrice")
                      }}</small></span
                    >
                  </td>
                  <td class="text-right">
                    <div class="font-weight-bold">
                      {{
                        func
                          .toMoneyFormat(
                            reservationData?.prices?.dailyRentalPrice[
                              reservationData.currency.code
                            ]
                          )
                          .toLocaleString(reservationData.lang, {
                            style: "currency",
                            currency: [reservationData.currency.code],
                          })
                      }}
                    </div>
                    <div
                      v-if="
                        reservationData?.prices?.dailyRentalPrice.TRY >
                        reservationData?.prices?.retailDailyRentalPrice.TRY
                      ">
                      <small>
                        {{
                          func
                            .toMoneyFormat(
                              reservationData?.prices?.retailDailyRentalPrice[
                                reservationData.currency.code
                              ]
                            )
                            .toLocaleString(reservationData.lang, {
                              style: "currency",
                              currency: [reservationData.currency.code],
                            })
                        }}</small
                      >
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      {{ $t("rental_price") }}
                    </strong>
                    <span
                      v-if="
                        reservationData.prices.totalRentalPrice.TRY >
                        reservationData.prices.retailTotalRentalPrice.TRY
                      "
                      ><br /><small>{{
                        $t("retailTotalRentalPrice")
                      }}</small></span
                    >
                  </td>
                  <td class="text-right">
                    <span>
                      <div class="font-weight-bold">
                        {{
                          func
                            .toMoneyFormat(
                              reservationData?.prices?.totalRentalPrice[
                                reservationData.currency.code
                              ]
                            )
                            .toLocaleString(reservationData.lang, {
                              style: "currency",
                              currency: [reservationData.currency.code],
                            })
                        }}
                      </div>
                      <div
                        v-if="
                          reservationData.prices.totalRentalPrice.TRY >
                          reservationData.prices.retailTotalRentalPrice.TRY
                        ">
                        <small>
                          {{
                            func
                              .toMoneyFormat(
                                reservationData?.prices?.retailTotalRentalPrice[
                                  reservationData.currency.code
                                ]
                              )
                              .toLocaleString(reservationData.lang, {
                                style: "currency",
                                currency: [reservationData.currency.code],
                              })
                          }}</small
                        >
                      </div>
                    </span>
                  </td>
                </tr>
                <template
                  v-if="
                    reservationData.prices?.totalExtraPrice[
                      reservationData.currency.code
                    ] > 0
                  ">
                  <tr
                    v-for="(data, key) in reservationData.selectedExtras"
                    :key="key">
                    <td style="color: gray">
                      <strong
                        ><v-icon> mdi-chevron-right </v-icon>
                        {{ data.name }}</strong
                      >
                    </td>
                    <td class="text-right" style="color: gray">
                      <span>
                        <div class="font-weight-bold">
                          {{
                            func
                              .toMoneyFormat(data.totalPrice)
                              [reservationData?.currency?.code].toLocaleString(
                                reservationData.lang,
                                {
                                  style: "currency",
                                  currency: [reservationData.currency.code],
                                }
                              )
                          }}
                        </div>
                        <span v-if="reservationData.currency.code != 'TRY'">
                          {{
                            func
                              .toMoneyFormat(
                                data.totalPrice[reservationData.currency.code]
                              )
                              .toLocaleString(reservationData.lang, {
                                style: "currency",
                                currency: [reservationData.currency.code],
                              })
                          }}
                        </span>
                      </span>
                    </td>
                  </tr>
                </template>
                <tr
                  v-if="
                    reservationData.prices?.totalExtraPrice[
                      reservationData.currency.code
                    ] > 0
                  ">
                  <td>
                    <strong>
                      {{ $t("total_extra_fee") }}
                    </strong>
                  </td>
                  <td class="text-right">
                    <div class="font-weight-bold">
                      {{
                        func
                          .toMoneyFormat(
                            reservationData.prices?.totalExtraPrice[
                              reservationData.currency.code
                            ]
                          )
                          .toLocaleString(reservationData.lang, {
                            style: "currency",
                            currency: [reservationData.currency.code],
                          })
                      }}
                    </div>
                  </td>
                </tr>
                <tr v-if="reservationData.prices.dropPrice.EUR > 0">
                  <td>
                    <strong
                      ><v-icon> mdi-chevron-right </v-icon>
                      {{ $t("drop_off_price") }}
                    </strong>
                  </td>
                  <td class="text-right">
                    <div class="font-weight-bold">
                      {{
                        func
                          .toMoneyFormat(
                            reservationData?.prices?.dropPrice[
                              reservationData.currency.code
                            ]
                          )
                          .toLocaleString(reservationData.lang, {
                            style: "currency",
                            currency: [reservationData.currency.code],
                          })
                      }}
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>
                      {{ $t("amount_payable_on_delivery") }}
                    </strong>
                  </td>
                  <td class="text-right">
                    <span>
                      <div class="font-weight-bold">
                        {{
                          func
                            .toMoneyFormat(
                              reservationData?.prices?.payLater[
                                reservationData.currency.code
                              ]
                            )
                            .toLocaleString(reservationData.lang, {
                              style: "currency",
                              currency: [reservationData.currency.code],
                            })
                        }}
                      </div>
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>
                      {{ $t("total_fee") }}
                    </strong>
                    <small>
                      {{ $t("excluding_deposit") }}
                    </small>
                  </td>
                  <td class="text-right">
                    <span v-if="oldPrice">
                      <div class="font-weight-bold">
                        {{
                          func.toMoneyFormat(
                            (reservationData?.prices?.totalPrice[
                              reservationData.currency.code
                            ]).toLocaleString(reservationData.lang, {
                              style: "currency",
                              currency: [reservationData.currency.code],
                            })
                          )
                        }}
                      </div>
                    </span>
                    <span v-else>
                      <div class="font-weight-bold">
                        {{
                          func.toMoneyFormat(
                            (reservationData?.prices?.totalPrice[
                              reservationData.currency.code
                            ]).toLocaleString(reservationData.lang, {
                              style: "currency",
                              currency: [reservationData.currency.code],
                            })
                          )
                        }}
                      </div>
                    </span>
                  </td>
                </tr>

                <tr v-if="reservationData.reservationType != 'fullCredit'">
                  <td style="color: gray">
                    <strong>
                      {{ $t("deposit") }}
                    </strong>
                  </td>
                  <td style="color: gray" class="text-right">
                    <span v-if="oldPrice">
                      <div class="font-weight-bold">
                        {{
                          func
                            .toMoneyFormat(
                              reservationData?.prices?.provision[
                                reservationData.currency.code
                              ]
                            )
                            .toLocaleString(reservationData.lang, {
                              style: "currency",
                              currency: [reservationData.currency.code],
                            })
                        }}
                      </div>
                    </span>
                    <span v-else>
                      <div class="font-weight-bold">
                        {{
                          func
                            .toMoneyFormat(
                              reservationData?.prices?.provision[
                                reservationData.currency.code
                              ]
                            )
                            .toLocaleString(reservationData.lang, {
                              style: "currency",
                              currency: [reservationData.currency.code],
                            })
                        }}
                      </div>
                    </span>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
          <!-- ----REZERVASYON DETAY BİTİŞ ----REZERVATION DETAIL END a ------->
        </v-col>
        <v-col cols="12" lg="7" md="6" sm="12" class="pa-0">
          <v-row>
            <v-col cols="12" lg="7" md="12" sm="12">
              <!-- ----SÜRÜCÜ BİLGİLERİ BAŞLANGIÇ ----DRİVER INFORMATION START ------->
              <v-card elevation="10">
                <v-toolbar
                  height="40"
                  dense
                  dark
                  color="grey darken-1"
                  elevation="0">
                  <v-toolbar-title style="font-size: 14px"
                    ><strong>
                      {{ $t("driver_info") }}
                    </strong></v-toolbar-title
                  >
                </v-toolbar>
                <div>
                  <v-row>
                    <v-col col="12" class="pa-0">
                      <v-simple-table dense>
                        <tbody>
                          <tr>
                            <td style="width: 140px">
                              <strong>
                                {{ $t("name") }}
                                {{ $t("lastname") }}
                              </strong>
                            </td>
                            <td>
                              {{ reservationData.driverInfo.name }}
                              {{ reservationData.driverInfo.lastname }}
                            </td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>
                              <strong> {{ $t("phone") }} </strong>
                            </td>
                            <td>{{ phoneFormat }}</td>
                            <td>
                              <v-btn
                              v-if="
                                  $store.state.GetServerData.departmanRole ===
                                  'CustomerExperience'&&$store.state.GetServerData.role=='SUPERUSER'&&$store.state.GetServerData.userType=='admin'
                                "
                                color="green lighten-1"
                                v-model="driverInfoPhone"
                                @click="driverInfoPhone = true"
                                small
                                >Güncelle</v-btn
                              >
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>
                                {{ $t("email") }}
                              </strong>
                            </td>
                            <td>{{ reservationData.driverInfo.email }}</td>
                            <td>
                              <v-btn
                                v-if="
                                  $store.state.GetServerData.departmanRole ===
                                  'CustomerExperience'&&$store.state.GetServerData.role=='SUPERUSER'&&$store.state.GetServerData.userType=='admin'
                                "
                                color="green lighten-1"
                                v-model="driverInfoEmail"
                                @click="driverInfoEmail = true"
                                small
                                >Güncelle</v-btn
                              >
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>
                                {{ $t("identity") }}
                              </strong>
                            </td>
                            <td>
                              {{
                                $store.state.ceviri[
                                  reservationData.driverInfo.identity
                                    .certificateType
                                ]
                              }}
                              / {{ reservationData.driverInfo.identity.value }}
                            </td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>
                              <strong>
                                {{ $t("date_of_birth") }}
                              </strong>
                            </td>
                            <td>
                              {{ birthdayMoment }}
                              ({{ birthdayMoment && yearMoment }}
                              {{ $t("years_old") }}

                              )
                            </td>
                            <td></td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </div>
              </v-card>
              <CModal title="Telefon" :show.sync="driverInfoPhone">
                <CRow>
                  <CCol sm="12">
                    <CInput label="Telefon" v-model="driverInfoPhoneForm" />
                  </CCol>
                </CRow>

                <template #footer>
                  <v-btn
                    @click="driverInfoUpdate('phone')"
                    class="primary"
                    small
                    >Güncelle</v-btn
                  >
                </template>
              </CModal>
              <CModal title="Email" :show.sync="driverInfoEmail">
                <CRow>
                  <CCol sm="12">
                    <CInput label="Email" v-model="driverInfoEmailForm" />
                  </CCol>
                </CRow>

                <template #footer>
                  <v-btn
                    @click="driverInfoUpdate('email')"
                    class="primary"
                    small
                    >Güncelle</v-btn
                  >
                </template>
              </CModal>

              <!-- ----SÜRÜCÜ BİLGİLERİ BİTİŞ ----DRİVER INFORMATION END ------->

              <!-- ----FATURA BİLGİLERİ BAŞLANGIÇ ----BILLING INFORMATION START ------->
              <v-card elevation="10" class="mt-4">
                <v-toolbar
                  height="40"
                  dense
                  dark
                  color="grey darken-1"
                  elevation="0"
                  v-if="invoicesStatus">
                  <v-toolbar-title style="font-size: 14px"
                    ><strong>
                      {{ $t("billing_information") }}
                    </strong></v-toolbar-title
                  >
                </v-toolbar>
                <div v-if="invoicesStatus">
                  <v-row>
                    <v-col col="12" class="pa-0">
                      <v-simple-table dense>
                        <tbody>
                          <tr>
                            <td style="width: 140px">
                              <strong>
                                {{ $t("type") }}
                              </strong>
                            </td>
                            <td>{{ invoiceDetail.invoiceType }}</td>
                          </tr>
                          <tr
                            v-if="
                              invoiceDetail.title &&
                              invoiceDetail.title !== 'undefined undefined'
                            ">
                            <td>
                              <strong>
                                {{ $t("company_title") }}
                              </strong>
                            </td>
                            <td>
                              {{ invoiceDetail.title }}
                            </td>
                          </tr>
                          <tr v-if="partnerBrand">
                            <td>
                              <strong>
                                {{ $t("brand") }}
                              </strong>
                            </td>
                            <td>{{ partnerBrand }}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>
                                {{ $t("address") }}
                              </strong>
                            </td>
                            <td>
                              {{ invoiceDetail.address }}
                            </td>
                          </tr>
                          <tr v-if="invoiceDetail.taxOffice">
                            <td>
                              <strong>
                                {{ $t("tax_number") }}
                              </strong>
                            </td>
                            <td>
                              {{ invoiceDetail.taxOffice }} /
                              {{ invoiceDetail.taxNumber }}
                            </td>
                          </tr>
                          <tr v-if="invoiceDetail.invoiceNumber">
                            <td>
                              <strong>
                                {{ $t("status") }}
                              </strong>
                            </td>
                            <td>
                              {{
                                invoiceDetail.invoiceTransferStatus == "ok"
                                  ? $t("invoiced")
                                  : invoiceDetail.invoiceTransferStatus ==
                                    "pending"
                                  ? $t("pending")
                                  : invoiceDetail.invoiceTransferStatus ==
                                    "error"
                                  ? $t("error_t")
                                  : ""
                              }}
                              <font-awesome-icon
                                v-if="invoiceDetail.invoiceERPMessage"
                                v-c-tooltip="{
                                  content: invoiceDetail.invoiceERPMessage,
                                  placement: 'top',
                                }"
                                :icon="['fas', 'info-circle']"
                                class="mr-2" />
                            </td>
                          </tr>
                          <tr v-if="invoiceDetail.invoiceNumber">
                            <td>
                              <strong> {{ $t("invoice_number") }} </strong>
                            </td>
                            <td>
                              {{ invoiceDetail.invoiceNumber }}
                            </td>
                          </tr>
                          <tr v-if="invoiceDetail.invoiceDate">
                            <td>
                              <strong>
                                {{ $t("invoice_date") }}
                              </strong>
                            </td>
                            <td>
                              {{
                                moment(invoiceDetail.invoiceDate).format(
                                  "DD-MM-YYYY"
                                )
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </div>
              </v-card>
              <!-- ----FATURA BİLGİLERİ BİTİŞ ----BILLING INFORMATION END ------->

              <!-- ----ÖDEME BİLGİLERİ  BAŞLANGIÇ ----PAYMENT INFORMATION START ------->
              <v-card
                elevation="10"
                class="mt-4"
                v-if="
                  reservationData.status != 'pending' &&
                  reservationData.status != 'paymentError' &&
                  reservationData.paymentType != 'debit'
                ">
                <v-toolbar
                  height="40"
                  dense
                  dark
                  color="grey darken-1"
                  elevation="0">
                  <v-toolbar-title style="font-size: 14px"
                    ><strong>
                      {{ $t("payment_info") }}
                    </strong></v-toolbar-title
                  >
                </v-toolbar>
                <div v-if="invoicesStatus">
                  <v-row>
                    <v-col col="12" class="pa-0">
                      <v-simple-table dense>
                        <tbody>
                          <tr>
                            <td style="width: 140px">
                              <strong>
                                {{ $t("amount_paid") }}
                              </strong>
                            </td>
                            <td>
                              {{
                                bankTransactions.TotalAmount
                                  ? func.toMoneyFormat(
                                      bankTransactions.TotalAmount
                                    ) + " ₺"
                                  : "0 ₺"
                              }}
                              <small
                                >(
                                {{
                                  bankTransactions.InstallmentCount == 1 ||
                                  !bankTransactions.InstallmentCount
                                    ? $t("advance")
                                    : $t("installment") +
                                      " /" +
                                      bankTransactions.InstallmentCount
                                }}
                                )</small
                              >
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <strong>
                                {{ $t("order_number") }}
                              </strong>
                            </td>
                            <td>
                              {{ reservationData && reservationData.orderId }}
                            </td>
                          </tr>
                          <tr v-if="bankTransactions.VposName">
                            <td>
                              <strong>
                                {{ $t("bank") }}
                              </strong>
                            </td>
                            <td>
                              {{ bankTransactions.VposName }}
                            </td>
                          </tr>
                          <tr v-if="bankTransactions.CardNumber">
                            <td>
                              <strong>
                                {{ $t("card_number") }}
                              </strong>
                            </td>
                            <td>
                              {{ bankTransactions.CardNumber }}
                            </td>
                          </tr>
                          <tr v-if="reservationData?.payments?.length">
                            <td>
                              <strong>
                                {{ $t("card_type") }}
                              </strong>
                            </td>
                            <td>
                              {{
                                reservationData.payments[0].creditCardCountry ==
                                "nativeCard"
                                  ? $t("local")
                                  : reservationData?.payments[0]
                                      ?.creditCardCountry == "otherCard"
                                  ? $t("abroad")
                                  : ""
                              }}
                            </td>
                          </tr>
                          <tr v-if="reservationData?.payments?.length">
                            <td>
                              <strong>POS</strong>
                            </td>
                            <td>
                              {{ reservationData?.payments[0]?.posModule }}
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </div>
              </v-card>

              <!-- ----TEDARİKÇİYE NOT  BAŞLANGIÇ ------->
              <v-card
                elevation="10"
                class="mt-4"
                v-if="reservationData.description">
                <v-toolbar
                  height="40"
                  dense
                  dark
                  color="grey darken-1"
                  elevation="0">
                  <v-toolbar-title style="font-size: 14px"
                    ><strong>
                      {{ $t("description_to_supplier") }}
                    </strong></v-toolbar-title
                  >
                </v-toolbar>
                <div class="pa-3">
                  {{ reservationData.description }}
                </div>
              </v-card>
              <!-- ---- ÖDEME BİLGİLERİ BİTİŞ ----PAYMENT INFORMATION END ------->
            </v-col>

            <v-col cols="12" lg="5" md="12" sm="12">
              <!-- GENEL BİLGİLER BAŞLANGIÇ ---- GENERAL INFORMATION START ------->
              <v-card elevation="10" class="mb-5">
                <v-toolbar
                  height="40"
                  dense
                  dark
                  color="grey darken-1"
                  elevation="0">
                  <v-toolbar-title style="font-size: 14px"
                    ><strong>{{ $t("general") }}</strong></v-toolbar-title
                  >
                </v-toolbar>
                <div>
                  <v-row>
                    <v-col col="12" class="pa-0">
                      <v-simple-table dense>
                        <tbody>
                          <tr>
                            <td style="width: 150px">
                              <strong>
                                {{ $t("status") }}
                              </strong>
                            </td>
                            <td>
                              {{ $store.state.ceviri[reservationData.status] }}
                            </td>
                          </tr>

                          <!-- <tr v-if="reservationData?.refund?.status==true">
                            <td >
                              <strong>
                                {{ $t("return_status") }}
                              </strong>
                            </td>
                            <td>{{ reservationData.refund.place}}</td>
                          </tr> -->

                          <tr>
                            <td>
                              <strong>
                                {{ $t("reservation_number") }}
                              </strong>
                            </td>
                            <td>{{ reservationData.reservationCode }}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>
                                {{ $t("vendor_reservation_number") }}
                              </strong>
                            </td>
                            <td>{{ reservationData.vendorReservationCode }}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>
                                {{ $t("day") }}
                              </strong>
                            </td>
                            <td>{{ reservationData.searchPrice.totalDays }}</td>
                          </tr>

                          <tr
                            v-if="
                              reservationData.status == `confirmedEarlyReturn`
                            ">
                            <td>
                              <strong class="text-decoration-underline">
                                {{ "İade Gün" }}
                              </strong>
                            </td>
                            <td class="text-decoration-underline">
                              {{ EarlyReturnCardInfo.DiffTime }}
                            </td>
                          </tr>
                          <tr v-if="reservationData.searchPrice.createdAt">
                            <td>
                              <strong>
                                {{ $t("search_date") }}
                              </strong>
                            </td>
                            <td style="font-size: 11px">
                              {{
                                moment(reservationData.searchPrice.createdAt)
                                  .local()
                                  .format("DD-MM-YYYY HH:mm")
                              }}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <strong>
                                {{ $t("process_date") }}
                              </strong>
                            </td>
                            <td style="font-size: 11px">
                              {{
                                moment(reservationData.reservationDateTime)
                                  .local()
                                  .format("DD-MM-YYYY HH:mm")
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>
                                {{ $t("trader") }}
                              </strong>
                            </td>
                            <td>
                              {{
                                reservationData?.modifications[0].userType ==
                                  "agent" ||
                                reservationData?.modifications[0].userType ==
                                  "admin"
                                  ? $store.state.ceviri[
                                      reservationData?.modifications[0]
                                        ?.departmanRole
                                    ]
                                  : $store.state.ceviri[
                                      reservationData?.modifications[0]
                                        ?.userType
                                    ]
                              }}
                              <small
                                class="font-weight-bold d-block"
                                v-if="reservationData.partnerDetail"
                                >{{ reservationData?.partnerDetail?.title }}
                              </small>
                              <small class="font-weight-bold d-block"
                                >{{
                                  reservationData?.modifications[0]?.fullname
                                }}
                              </small>
                            </td>
                          </tr>
                          <tr v-if="reservationData.paymentType">
                            <td>
                              <strong>
                                {{ $t("payment_type") }}
                              </strong>
                            </td>
                            <td>
                              {{
                                $store.state.ceviri[reservationData.paymentType]
                              }}
                            </td>
                          </tr>
                          <tr v-if="reservationData.reservationType">
                            <td>
                              <strong>
                                {{ $t("reservation_type") }}
                              </strong>
                            </td>
                            <td>
                              {{ $t(reservationData.reservationType) }}
                            </td>
                          </tr>
                          <tr
                            v-if="
                              reservationData?.currency?.forexSelling > 1 &&
                              !isPartner
                            ">
                            <td>
                              <strong> Kur </strong>
                            </td>
                            <td>
                              {{ reservationData?.currency?.forexSelling }}
                            </td>
                          </tr>

                          <tr v-if="reservationData?.utmSource?.length">
                            <td>
                              <strong>
                                {{ $t("Kaynak") }}
                              </strong>
                            </td>
                            <td>
                              {{ reservationData.utmSource }}
                            </td>
                          </tr>
                          <tr
                            v-if="
                              reservationData?.metropolCard?.length && isPay
                            ">
                            <td>
                              <strong> Metropol Kart </strong>
                            </td>
                            <td>
                              {{ reservationData?.metropolCard }}
                            </td>
                          </tr>
                          <tr
                            v-if="
                              reservationData.driverInfo.flightNo &&
                              reservationData.driverInfo.flightNo != ''
                            ">
                            <td>
                              <strong>
                                {{ $t("flight_number") }}
                              </strong>
                            </td>
                            <td>
                              {{ reservationData.driverInfo.flightNo }}
                            </td>
                          </tr>
                          <!-- <tr v-if="reservationData.status == 'cancelled'">
                            <td>
                              <strong>
                                {{ $t("cancel_date") }}
                              </strong>
                            </td>
                            <td>
                              {{
                                moment(reservationCancellationDate)
                                  .local()
                                  .format("DD-MM-YYYY HH:mm")
                              }}
                            </td>
                          </tr>

                          <tr v-if="reservationData.status == 'cancelled'">
                            <td>
                              <strong>
                                {{ $t("cancel_author") }}
                              </strong>
                            </td>
                            <td>
                              {{
                                reservationCancelData?.departmanRole
                                  ? $store.state.ceviri[
                                      reservationCancelData?.departmanRole
                                    ]
                                  : reservationCancelData?.place
                              }}
                              <small class="font-weight-bold d-block"
                                >{{ reservationCancelData?.author }}
                              </small>
                            </td>
                          </tr>

                          <tr v-if="reservationData?.status == 'cancelled'">
                            <td>
                              <strong>
                                {{ $t("reason_for_cancellation") }}
                              </strong>
                            </td>
                            <td>
                              {{ reservationCancelData?.message }}
                            </td>
                          </tr> -->
                        </tbody>
                      </v-simple-table>

                      <v-col
                        v-if="
                          reservationData.status != 'paymentError' &&
                          reservationData.status != 'pending'
                        ">
                        <v-btn
                          class="mb-1 down-btn"
                          block
                          outlined
                          small
                          color="primary font-weight-bold"
                          v-if="
                            reservationData.status != 'pending' &&
                            reservationData.status != 'cancelled' &&
                            reservationData.status != 'completed'
                          "
                          @click="reservationEmailSend(reservationData._id)"
                          style="white-space: normal; word-wrap: break-word">
                          {{ $t("send_reservation_email") }}
                        </v-btn>
                        <v-btn
                          class="mb-1 down-btn"
                          block
                          small
                          color="primary font-weight-bold"
                          v-if="
                            reservationData.status == 'pending' ||
                            reservationData.status == 'paymentError'
                          "
                          @click="beginIVR(reservationData.reservationCode)"
                          style="white-space: normal; word-wrap: break-word">
                          {{ $t("forward_call_ivr") }}
                        </v-btn>

                        <v-btn
                          class="mb-1 down-btn"
                          block
                          outlined
                          small
                          color="primary  font-weight-bold"
                          v-if="reservationData.status != 'pending'"
                          @click="
                            reservationPdfDownload(
                              reservationData.driverInfo.email,
                              reservationData.reservationCode
                            )
                          "
                          style="white-space: normal; word-wrap: break-word">
                          {{ $t("download_reservation_document") }}
                        </v-btn>
                        <RoleProvider slug="RESERVATION_EARLY_RETURN_BEGIN">
                          <div slot-scope="{ canItPass }">
                            <v-btn
                              class="mb-1"
                              block
                              small
                              color="warning text-white font-weight-bold"
                              @click="earlyReturnModal = true"
                              v-if="earlyReturnRequestButton() && canItPass">
                              {{ $t("early_return_request") }}
                            </v-btn>
                          </div>
                        </RoleProvider>
                        <RoleProvider slug="RESERVATION_EARLY_RETURN_BEGIN">
                          <div slot-scope="{ canItPass }">
                            <v-btn
                              class="mb-1"
                              block
                              small
                              color="warning text-white font-weight-bold"
                              @click="formEarlyReturnRegistrationModal = true"
                              v-if="
                                reservationData.status ==
                                  'earlyReturnRequest' ||
                                (reservationData.status ==
                                  'earlyReturnRejected' &&
                                  canItPass)
                              ">
                              {{ $t("early_return_registration") }}
                            </v-btn>
                          </div>
                        </RoleProvider>
                        <RoleProvider slug="RESERVATION_EARLY_RETURN_BEGIN">
                          <div slot-scope="{ canItPass }">
                            <v-btn
                              class="mb-1"
                              v-if="
                                reservationData.status ==
                                  'earlyReturnFinancialConfirmation' &&
                                canItPass
                              "
                              small
                              block
                              color="error font-weight-bold"
                              @click="rejectEarlyReturn()">
                              {{ $t("early_return_rejection") }}
                            </v-btn>
                          </div>
                        </RoleProvider>
                        <!-- ////////////////////////// -->

                        <RoleProvider slug="RESERVATION_REFUND_PAYMENT">
                          <div slot-scope="{ canItPass }">
                            <v-btn
                              class="mb-1"
                              block
                              small
                              color="secondary text-white font-weight-bold"
                              v-if="
                                reservationData.paymentType == `debit` &&
                                (reservationData.status == `active` ||
                                  reservationData.status == `completed`)
                              "
                              @click="refundPaymentModel = true">
                              {{ $t("refund_payment") }}
                            </v-btn>
                          </div>
                        </RoleProvider>

                        <RoleProvider slug="RESERVATION_REFUND_PAYMENT">
                          <div slot-scope="{ canItPass }">
                            <v-btn
                              class="mb-1"
                              block
                              small
                              color="secondary text-white font-weight-bold"
                              @click="noShowRefundPaymentModel = true">
                              {{ $t("noshow_refund_payment") }}
                            </v-btn>
                          </div>
                        </RoleProvider>

                        <!-- ///////////////////////////////// -->
                        <RoleProvider
                          slug="RESERVATION_EARLY_RETURN_SUCCESS_REJECT">
                          <div slot-scope="{ canItPass }">
                            <v-btn
                              class="mb-1"
                              small
                              v-if="
                                reservationData.status ==
                                  'earlyReturnFinancialConfirmation' &&
                                canItPass
                              "
                              block
                              color="success text-white font-weight-bold"
                              @click="confirmEarlyReturn()">
                              {{ $t("early_return_confirmation") }}
                            </v-btn>
                          </div>
                        </RoleProvider>
                        <RoleProvider slug="RESERVATION_COMMENT_CREATE">
                          <div slot-scope="{ canItPass }">
                            <v-btn
                              class="mb-1"
                              block
                              small
                              color="warning text-white font-weight-bold"
                              v-if="
                                reservationData.status == 'completed' &&
                                canItPass
                              "
                              @click="
                                surveySend(reservationData.reservationCode)
                              ">
                              {{ $t("send_survey") }}
                            </v-btn>
                          </div>
                        </RoleProvider>
                        <RoleProvider slug="RESERVATION_DELETE">
                          <div slot-scope="{ canItPass }">
                            <v-btn
                              class="mb-1"
                              small
                              block
                              color="error font-weight-bold"
                              v-if="
                                reservationData.status !== 'cancelled' &&
                                reservationData.status !==
                                  'earlyReturnRequest' &&
                                reservationData.status !==
                                  'earlyReturnFinancialConfirmation' &&
                                reservationData.status !== 'pending' &&
                                reservationData.consensus.status !=
                                  'completed' &&
                                canItPass
                              "
                              @click="cacelBtnClick('all')">
                              {{ $t("cancel_btn") }}
                            </v-btn>
                          </div>
                        </RoleProvider>
                        <RoleProvider slug="RESERVATION_DELETE">
                          <div slot-scope="{ canItPass }">
                            <v-btn
                              class="mb-1"
                              small
                              block
                              color="light"
                              @click="reservationSetting.modalShow = true"
                              v-if="
                                canItPass &&
                                reservationData.status != 'cancelled'
                              ">
                              {{ $t("change") }}
                            </v-btn>
                          </div>
                        </RoleProvider>
                      </v-col>
                      <v-col>
                        <v-btn
                          class="mb-1"
                          block
                          small
                          color="primary font-weight-bold"
                          v-if="
                            reservationData.paymentType == 'noneSecurePos' &&
                            !isPay
                          "
                          @click="paymentModalShow = !paymentModalShow">
                          {{ $t("pay_with_virtual_pos") }}
                        </v-btn>
                      </v-col>
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- GENEL BİLGİLER BİTİŞ ---- GENERAL INFORMATION END ------->

      <v-row>
        <v-col md="12" sm="12" cols="12">
          <!-- İPTAL BİLGİLER KART BAŞLANGIÇ ---- CANCEL INFORMATIONCARD  START ------->
          <v-card elevation="10" class="mb-5" v-if="cancelRezCarInfo?.CardShow">
            <v-toolbar
              height="40"
              dense
              dark
              class="grey darken-1"
              elevation="0">
              <v-toolbar-title style="font-size: 14px"
                ><strong>
                  {{ cancelRezCarInfo.title }}
                </strong></v-toolbar-title
              >
            </v-toolbar>
            <v-card-text class="px-4 pt-2 pb-3">
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <strong>İŞLEM TİPİ:</strong>
                  {{ cancelRezCarInfo.type }}
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <strong>İADE EDİLEN TUTAR:</strong>
                  {{ cancelRezCarInfo.refundPrice }}
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <strong>İPTAL EDEN KULLANICI:</strong>
                  {{ cancelRezCarInfo.personİnfo }}
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  v-if="cancelRezCarInfo.place !== `Agent`">
                  <strong>İPTAL EDEN :</strong>
                  {{ cancelRezCarInfo.place }}
                </v-col>
                <v-col cols="12" sm="6" md="4" v-else>
                  <strong>İPTAL EDEN DEPARTMANI:</strong>
                  {{ cancelRezCarInfo.departmanRole }}
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <strong>İPTAL TARİHİ:</strong>
                  {{
                    moment(cancelRezCarInfo.date)
                      .local()
                      .format("DD-MM-YYYY HH:mm")
                  }}
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <strong>İPTAL MESAJI:</strong>
                  {{ cancelRezCarInfo.message }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <!-- İPTAL BİLGİLER KART BİTİŞ ---- CANCEL INFORMATIONCARD  END ------->

          <!-- NOSHOW KART BAŞLANGIÇ ---- NOSHOW CARD  START ------->
          <!-- <v-card elevation="10" class="mb-5" v-if="noShowRefundCardInfo.CardShow">
            <v-toolbar height="40" dense dark class="white--text" style="background-color: red;" elevation="0">
              <v-toolbar-title style="font-size: 14px "><strong>
                  {{ noShowRefundCardInfo.title }}
                </strong></v-toolbar-title>
            </v-toolbar>

            <CCardText class="mb-1">
              <v-row>
                <v-col class="col-3">
                  {{ "İŞLEM TİPİ :" }}
                </v-col>
                <v-col class="col-3  font-weight-bold">
                  {{ noShowRefundCardInfo.type }}
                </v-col>
                <v-divider vertical></v-divider>
                <v-col class="col-3">
                  {{ "İPTAL EDEN KULLANICI TİPİ :" }}
                </v-col>
                <v-col class="col-3  font-weight-bold">
                  {{ noShowRefundCardInfo.person }}
                </v-col>
              </v-row>

              <v-row>
                <v-col class="col-3">
                  {{ "İPTAL EDEN KULLANICI :" }}
                </v-col>
                <v-col class="col-3  font-weight-bold">
                  {{ noShowRefundCardInfo.personİnfo }}
                </v-col>
                <v-divider vertical></v-divider>
                <v-col class="col-3">
                  {{ "İPTAL EDEN DEPARTMANI : " }}
                </v-col>
                <v-col class="col-3 t font-weight-bold">
                  {{ noShowRefundCardInfo.departmanRole }}
                </v-col>
              </v-row>

              <v-row>
                <v-col class="col-3">
                  {{ "İPTAL TARİHİ" }}
                </v-col>
                <v-col class="col-3  font-weight-bold">

                  {{ moment(noShowRefundCardInfo.date).local().format("DD-MM-YYYY HH:mm")
                  }}
                </v-col>
                <v-divider vertical></v-divider>
                <v-col class="col-3">
                  {{ "İPTAL MESAJI :" }}
                </v-col>
                <v-col class="col-3  font-weight-bold">
                  {{ noShowRefundCardInfo.message }}
                </v-col>
              </v-row>


              <v-row>
                <v-col class="col-3">
                  {{ "İPTAL EDEN MESAJI" }}
                </v-col>
                <v-col class="col-3 font-weight-bold">
                  {{ noShowRefundCardInfo.auditsMessage }}
                </v-col>
                <v-divider vertical></v-divider>
                <v-col class="col-3">
                  {{ "ALINAN ÖDEME TİPİ :" }}
                </v-col>
                <v-col class="col-3 font-weight-bold">

                  {{ $store.state.ceviri[noShowRefundCardInfo.paymentType]
                  }}
                </v-col>
              </v-row>

              <v-row v-if="noShowRefundCardInfo.paymentType !== `debit`">
                <v-col class="col-3">
                  {{ "İADE EDİLEN TOPLAM TUTAR:" }}
                </v-col>
                <v-col class="col-3 font-weight-bold">
                  {{ noShowRefundCardInfo.refundPrice }}
                </v-col>
              </v-row>
              <v-row v-if="noShowRefundCardInfo.paymentType == `debit`">
                <v-col class="col-3">
                  {{ "İADESİ TALEP EDİLEN TUTAR" }}
                </v-col>
                <v-col class="col-3 font-weight-bold">
                  {{ noShowRefundCardInfo.reqPrice }}
                </v-col>
              </v-row>
            </CCardText>

          </v-card> -->
          <!-- --------------------------------- -->
          <v-card
            elevation="10"
            class="mb-5"
            v-if="noShowRefundCardInfo.CardShow">
            <v-toolbar
              height="40"
              dense
              dark
              class="grey darken-1"
              elevation="0">
              <v-toolbar-title style="font-size: 14px"
                ><strong>
                  {{ noShowRefundCardInfo.title }}
                </strong></v-toolbar-title
              >
            </v-toolbar>
            <v-card-text class="px-4 pt-2 pb-3">
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <strong>İptal Tarihi :</strong>
                  {{
                    moment(noShowRefundCardInfo.date)
                      .local()
                      .format("DD-MM-YYYY HH:mm")
                  }}
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  v-if="noShowRefundCardInfo.paymentType !== `debit`">
                  <strong>İade Edilen Tutar:</strong>
                  {{ noShowRefundCardInfo.refundPrice }}
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  v-if="noShowRefundCardInfo.paymentType == `debit`">
                  <strong>İadesi Talep Edilen Tutar:</strong>
                  {{ noShowRefundCardInfo.reqPrice }}
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <strong>İşlemi Gerçekleştiren:</strong>
                  {{ noShowRefundCardInfo.personİnfo }}{{ "/" }}
                  {{ noShowRefundCardInfo.departmanRole }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <!-- NOSHOW KART BİTİŞ ---- NOSHOW CARD  END ------->

          <!-- ERKEN İADE KART BAŞLANGIÇ ---- EARLYRETURN  CARD  START ------->
          <v-card
            elevation="10"
            class="mb-5"
            v-if="EarlyReturnCardInfo.CardShow">
            <v-toolbar
              height="40"
              dense
              dark
              class="grey darken-1"
              elevation="0">
              <v-toolbar-title style="font-size: 14px"
                ><strong>
                  {{ EarlyReturnCardInfo.title }}
                </strong></v-toolbar-title
              >
            </v-toolbar>
            <v-card-text class="px-4 pt-2 pb-3">
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <strong>İade Tarihi :</strong>
                  {{
                    moment(EarlyReturnCardInfo.earlyReturnDate)
                      .local()
                      .format("D MMMM YYYY")
                  }}
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <strong>İade Ücreti:</strong>
                  {{ EarlyReturnCardInfo.earlyReturnPayable }}
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <strong>İade Mesajı:</strong>
                  {{ EarlyReturnCardInfo.message }}
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <strong>İşlemi Gerçekleştiren:</strong>
                  {{ EarlyReturnCardInfo.personİnfo }}
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <strong>Departman:</strong>
                  {{ EarlyReturnCardInfo.departmanRole }}
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <strong>İşlem Tarihi:</strong>
                  {{
                    moment(EarlyReturnCardInfo.date)
                      .local()
                      .format("D MMMM YYYY")
                  }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <!-- ERKEN İADE KART BİTİŞ ---- EARLYRETURN CARD  END ------->

          <!-- İADE ÖDEMESİ KART BAŞLANGIÇ ---- İADE ÖDEMESİ CARD  START ------->
          <!-- <v-card elevation="10" class="mb-5" v-if="refundPayCardInfo.CardShow">
            <v-toolbar height="40" dense dark class="white--text" style="background-color: red;" elevation="0">
              <v-toolbar-title style="font-size: 14px "><strong>
                  {{ refundPayCardInfo.title }}
                </strong></v-toolbar-title>
            </v-toolbar>

            <CCardText class="mb-1">
              <v-row>
                <v-col class="col-3">
                  {{ "İADE TİPİ :" }}
                </v-col>
                <v-col class="col-3  font-weight-bold">
                  {{ refundPayCardInfo.type }}
                </v-col>
                <v-divider vertical></v-divider>
                <v-col class="col-3">
                  {{ "İADE EDEN KULLANICI TİPİ :" }}
                </v-col>
                <v-col class="col-3  font-weight-bold">
                  {{ refundPayCardInfo.person }}
                </v-col>
              </v-row>

              <v-row>
                <v-col class="col-3">
                  {{ "İADE EDEN KULLANICI :" }}
                </v-col>
                <v-col class="col-3  font-weight-bold">
                  {{ refundPayCardInfo.personİnfo }}
                </v-col>
                <v-divider vertical></v-divider>
                <v-col class="col-3">
                  {{ "İADE EDEN DEPARTMANI : " }}
                </v-col>
                <v-col class="col-3 t font-weight-bold">
                  {{ refundPayCardInfo.departmanRole }}
                </v-col>
              </v-row>

              <v-row>
                <v-col class="col-3">
                  {{ "İADE TARİHİ" }}
                </v-col>
                <v-col class="col-3  font-weight-bold">

                  {{ moment(refundPayCardInfo.date).local().format("DD-MM-YYYY HH:mm")
                  }}
                </v-col>
                <v-divider vertical></v-divider>
                <v-col class="col-3">
                  {{ "İADE MESAJI :" }}
                </v-col>
                <v-col class="col-3  font-weight-bold">
                  {{ refundPayCardInfo.message }}
                </v-col>
              </v-row>


              <v-row>
                <v-col class="col-3">
                  {{ "İADE EDEN MESAJI" }}
                </v-col>
                <v-col class="col-3 font-weight-bold">
                  {{ refundPayCardInfo.auditsMessage }}
                </v-col>
                <v-divider vertical></v-divider>
                <v-col class="col-3">
                  {{ "ALINAN ÖDEME TİPİ :" }}
                </v-col>
                <v-col class="col-3 font-weight-bold">

                  {{ $store.state.ceviri[refundPayCardInfo.paymentType]
                  }}
                </v-col>
              </v-row>

              <v-row>
                <v-col class="col-3">
                  {{ "İADE EDİLEN TOPLAM TUTAR:" }}
                </v-col>
                <v-col class="col-3 font-weight-bold">
                  {{ refundPayCardInfo.refundPrice }}
                </v-col>
              </v-row>

            </CCardText>
          </v-card> -->

          <v-card elevation="10" class="mb-5" v-if="refundPayCardInfo.CardShow">
            <v-toolbar
              height="40"
              dense
              dark
              class="grey darken-1"
              elevation="0">
              <v-toolbar-title style="font-size: 14px"
                ><strong>
                  {{ refundPayCardInfo.title }}
                </strong></v-toolbar-title
              >
            </v-toolbar>
            <v-card-text class="px-4 pt-2 pb-3">
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <strong>İptal Tarihi :</strong>
                  {{
                    moment(refundPayCardInfo.date)
                      .local()
                      .format("DD-MM-YYYY HH:mm")
                  }}
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  v-if="refundPayCardInfo.paymentType !== `debit`">
                  <strong>İade Edilen Tutar:</strong>
                  {{ refundPayCardInfo.refundPrice }}
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  v-if="refundPayCardInfo.paymentType == `debit`">
                  <strong>İadesi Talep Edilen Tutar:</strong>
                  {{ refundPayCardInfo.reqPrice }}
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <strong>İşlemi Gerçekleştiren:</strong>
                  {{ refundPayCardInfo.personİnfo }}{{ "/" }}
                  {{ refundPayCardInfo.departmanRole }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <!-- İADE ÖDEMESİ KART BİTİŞ ---- İADE ÖDEMESİ CARD  END ------->

          <!-- ÖDEMELER BAŞLANGIÇ ------ PAYMENTS START  ----->
          <v-card
            elevation="10"
            class="mb-5"
            v-if="reservationData?.payments?.length > 0">
            <v-toolbar
              height="40"
              dense
              dark
              color="grey darken-1"
              elevation="0">
              <v-toolbar-title style="font-size: 14px"
                ><strong>
                  {{ $t("payments") }}
                </strong></v-toolbar-title
              >
            </v-toolbar>

            <v-simple-table>
              <thead>
                <tr>
                  <th>Order ID</th>
                  <th>
                    {{ $t("type") }}
                  </th>
                  <th>
                    {{ $t("channel") }}
                  </th>
                  <th>
                    {{ $t("date") }}
                  </th>
                  <th>ERP</th>
                  <th class="text-right">
                    {{ $t("amount") }}
                  </th>
                  <th
                    v-if="reservationData.consensus.status != 'completed'"
                    class="text-right">
                    {{ $t("early_return") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="payment in reservationData?.payments"
                  :key="payment._id">
                  <td>{{ payment?.orderId }}</td>
                  <td>
                    {{
                      payment?.paymentType == "payment"
                        ? $t("collection")
                        : $t("early_return")
                    }}
                  </td>
                  <td>
                    {{
                      payment?.channel == "pos"
                        ? $t("virtual_pos")
                        : payment?.channel
                    }}
                  </td>

                  <td>
                    {{
                      moment(payment?.created)
                        .local()
                        .format("DD-MM-YYYY HH:mm")
                    }}
                  </td>
                  <td>
                    {{
                      payment?.erpStatus == "pending"
                        ? $t("pending")
                        : payment?.erpStatus == "ok"
                        ? $t("transferred")
                        : $t("error_t")
                    }}
                    <font-awesome-icon
                      v-if="payment.erpError"
                      v-c-tooltip="{
                        content: payment?.erpError,
                        placement: 'top',
                      }"
                      :icon="['fas', 'info-circle']"
                      class="mr-2" />
                  </td>
                  <td
                    class="text-right"
                    style="font-weight: bolder"
                    v-if="payment?.totalAmount">
                    {{
                      payment?.totalAmount.toLocaleString("tr-TR", {
                        style: "currency",
                        currency: "TRY",
                      })
                    }}
                  </td>
                  <td>
                    <RoleProvider slug="RESERVATION_REFUND">
                      <div slot-scope="{ canItPass }">
                        <v-btn
                          v-if="
                            payment.paymentType == 'payment' &&
                            !isPartner &&
                            reservationData.consensus.status != 'completed' &&
                            canItPass
                          "
                          small
                          block
                          color="error"
                          @click="cacelBtnClick('single')"
                          size="sm">
                          {{ $t("early_return_btn") }}
                        </v-btn>
                      </div>
                    </RoleProvider>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
          <!-- ÖDEMELER BİTİŞ ------ PAYMENTS END  ----->

          <!-- POS HAREKETLERİ BAŞLANGIÇ ------ POS TRANSACTİONS START  ----->
          <v-card
            elevation="10"
            class="mb-5"
            v-if="
              reservationData.status != 'pending' &&
              reservationData.paymentType != 'debit'
            ">
            <v-toolbar
              height="40"
              dense
              dark
              color="grey darken-1"
              elevation="0">
              <v-toolbar-title style="font-size: 14px"
                ><strong>
                  {{ $t("pos_transactions") }}
                </strong></v-toolbar-title
              >
            </v-toolbar>

            <v-simple-table dense>
              <thead>
                <tr>
                  <th>
                    {{ $t("process_t") }}
                  </th>
                  <th>
                    {{ $t("date") }}
                  </th>
                  <th>
                    {{ $t("message") }}
                  </th>
                  <th>
                    {{ $t("card") }}
                  </th>
                  <th>
                    {{ $t("amount") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="bankitem in reservationData.bankTransactions"
                  :key="bankitem._id"
                  :class="
                    bankitem.type === 'paymentError' ||
                    bankitem.type === 'paymentFail'
                      ? 'text-danger'
                      : 'text-success'
                  ">
                  <td>
                    {{
                      bankitem.langName
                        ? bankitem.langName.TR || bankitem.langName.tr
                        : bankitem.type
                    }}
                  </td>
                  <td>
                    {{
                      bankitem.createdAt
                        | moment("add", "3 hours", "DD-MM-YYYY HH:mm:ss")
                    }}
                  </td>
                  <td>{{ bankitem.code }} {{ bankitem.message }}</td>
                  <td>{{ bankitem.data.CardNumber }}</td>
                  <td>
                    {{
                      bankitem.data.TotalAmount &&
                      bankitem.data.TotalAmount + " TL"
                    }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
          <!-- POS HAREKETLERİ BİTİŞ ------ POS TRANSACTİONS END  ----->

          <!-- ACENTE NOTLARI BAŞLANGIÇ ------ AGENT NOTES START  ----->
          <v-card elevation="10" class="mb-5">
            <v-toolbar
              height="40"
              dense
              dark
              color="grey darken-1"
              elevation="0">
              <v-toolbar-title style="font-size: 14px"
                ><strong>
                  {{ $t("agent_note") }}
                </strong></v-toolbar-title
              >
            </v-toolbar>
            <AgentNote
              :_id="reservationData._id"
              :slug="'reservations'"
              :total="agentNotesTotal" />
          </v-card>
          <!-- ACENTE NOTLARI BİTİŞ ------ AGENT NOTES END  ----->

          <!-- REZERVASYON ANKETİ BAŞLANGIÇ ------ REZERVATİON SURVET START  ----->
          <v-card
            elevation="10"
            class="mb-5"
            v-if="reservationData.status == 'completed'">
            <v-toolbar
              height="40"
              dense
              dark
              color="grey darken-1"
              elevation="0">
              <v-toolbar-title style="font-size: 14px"
                ><strong>
                  {{ $t("reservation_survey") }}
                </strong></v-toolbar-title
              >
            </v-toolbar>

            <Comment
              :_id="reservationData._id"
              :reservationCode="reservationData.reservationCode"
              :slug="'reservations'" />
          </v-card>
        </v-col>
      </v-row>
    </div>
    <gogo-loading v-else />
    <!-- REZERVATİON CANCELLATION MODAL START  ----->
    <CModal
      close-on-backdrop
      :title="`${reservationData.reservationCode}  ${$t('rejection_desc3')}`"
      size="lg"
      :show.sync="reservastionCancelModal">
      <v-row>
        <v-col>
          <h3>
            {{ $t("rejection_desc") }}
          </h3>
        </v-col>
      </v-row>

      <v-row form class="form-group">
        <v-col>
          <CInputRadio
            v-for="option in reasonsOfCancel"
            :key="option._id"
            name="reasonCancel"
            :label="option.question"
            :value="option.question"
            @update:checked="() => (reasonCancel = option.question)" />
          <CInputRadio
            name="reasonCancel"
            :label="$t('other')"
            @update:checked="() => (reasonCancel = 1)" />
        </v-col>
      </v-row>
      <v-row v-if="reasonCancel == 1">
        <v-col>
          <CTextarea v-model="otherReasonCancel" :placeholder="$t('leave')" />
        </v-col>
      </v-row>
      <template #footer>
        <CButton
          @click="reservastionCancelModal = false"
          :disabled="statusOfCancellation"
          >{{ $t("leave") }}</CButton
        >
        <CButton
          color="danger"
          :disabled="statusOfCancellation"
          @click="reservationStatusUpdate('cancelled')"
          >{{ $t("send") }}
        </CButton>
      </template>
    </CModal>
    <!--REZERVATİON CANCELLATION MODAL END  ----->

    <!-- EARLY RETURN  MODAL START  ----->
    <CModal
      close-on-backdrop
      :title="`${reservationData.reservationCode} ` + $t('early_return_info2')"
      size="lg"
      :show.sync="earlyReturnModal">
      <v-row>
        <v-col>
          <h3>{{ $t("early_return_info3") }}</h3>
        </v-col>
      </v-row>

      <v-row form class="form-group">
        <v-col>
          <CInputRadio
            v-for="option in reasonsOfEarlyReturn"
            :key="option._id"
            name="reasonCancel"
            :label="option.question"
            :value="option.question"
            @update:checked="() => (reasonEarlyReturn = option.question)" />
          <CInputRadio
            name="reasonCancel"
            :label="$t('other')"
            @update:checked="() => (openEarlyTextarea = true)" />
        </v-col>
      </v-row>
      <v-row v-if="openEarlyTextarea">
        <v-col>
          <CTextarea
            v-model="reasonEarlyReturn"
            :placeholder="$t('your_explanation')" />
        </v-col>
      </v-row>
      <template #footer>
        <CButton @click="earlyReturnModal = false">
          {{ $t("leave") }}
        </CButton>
        <CButton color="danger" @click="earlyReturnRequest()">
          {{ $t("send") }}
        </CButton>
      </template>
    </CModal>
    <!--  EARLY RETURN  MODAL END  ----->

    <!-- EARLY  RETURN REGISTRATION FORM  MODAL START  ----->

    <CModal
      :close-on-backdrop="false"
      size="l"
      :show.sync="formEarlyReturnRegistrationModal"
      v-if="reservationData.carDeliveryReturn"
      title="Erken İade Tutar ve Tarih Giriniz">
      <v-col>
        <label style="font-weight: bold"> {{ $t("returned_date") }} </label>
        <br /><small style="color: red; font-weight: 200">{{
          $t("early_return_description")
        }}</small>
        <VueCtkDateTimePicker
          no-shortcuts
          no-header
          :minDate="reservationData.carDeliveryReturn.delivery.datetime"
          :maxDate="reservationData.carDeliveryReturn.return.datetime"
          format="YYYY-MM-DD HH:mm"
          formatted="Do MMM YYYY ddd HH:mm"
          v-model="earlyReturnDate"
          :locale="locale"
          :label="$t('returned_date')" />
      </v-col>
      <v-col>
        <CInput
          :label="$t('refund_payment_amount')"
          class="font-weight-bold"
          v-model.number="earlyReturnPaymentAmount"
          type="number" />
      </v-col>

      <v-col>
        <v-row>
          <v-col class="col-6">
            <strong>İade Tarihi:</strong>
            {{
              earlyReturnDate
                ? moment(earlyReturnDate).local().format("D MMMM YYYY")
                : "-"
            }}
          </v-col>
          <v-col class="col-6">
            <strong>İade Ücreti:</strong>
            {{ earlyReturnPaymentAmount }}</v-col
          ></v-row
        >
      </v-col>

      <template #footer>
        <CButton
          @click="formEarlyReturnRegistrationModal = false"
          :disabled="statusOfEarlyPayment">
          {{ $t("leave") }}
        </CButton>
        <CButton
          color="danger"
          style="color: white"
          :disabled="statusOfEarlyPayment"
          @click="earlyReturnRegistration()">
          {{ $t("send") }}
        </CButton>
      </template>
    </CModal>
    <!-- EARLY  RETURN REGISTRATION FORM  MODAL   END  ----->

    <!--PAYMENT MODAL START  ----->

    <CModal
      size="lg"
      class="vendorModalAddUpdateForm modalNoBorder"
      title="Ödeme"
      :show.sync="paymentModalShow"
      :close-on-backdrop="false">
      <CCard class="p-0 m-0 cardShadow">
        <CCardBody>
          <validation-observer ref="refValidationNoneSecure">
            <CContainer class="p-4 m-0">
              <v-row>
                <v-col>
                  <CInput
                    v-mask="'#### #### #### ####'"
                    :placeholder="$t('card_number2')"
                    :label="$t('card_number2')"
                    v-model="paymentNoneSecureForm.cardNumber"
                    :value="paymentNoneSecureForm.cardNumber"
                    ref="cardNumber" />
                  <CInput
                    type="text"
                    :placeholder="$t('owner_of_the_card_number')"
                    :label="$t('owner_of_the_card_number')"
                    v-model="paymentNoneSecureForm.cardOwner"
                    :value="paymentNoneSecureForm.cardOwner" />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <CInput
                    v-model="paymentNoneSecureForm.expiry"
                    placeholder="AA/YY"
                    :label="$t('month') + '/' + $t('year')"
                    v-mask="`##/##`" />
                </v-col>

                <v-col>
                  <CInput
                    v-model="paymentNoneSecureForm.cvv"
                    :placeholder="$t('secure_code')"
                    :label="$t('secure_code')"
                    v-mask="`###`" />
                </v-col>
              </v-row>
            </CContainer>
          </validation-observer>
        </CCardBody>
      </CCard>

      <template #footer>
        <CButton
          @click="makeNoneSecurePayment"
          :disabled="createPaymentBtnLoading"
          color="success">
          <font-awesome-icon
            :icon="['fas', 'circle-notch']"
            size="lg"
            class="spin mr-2"
            v-show="createPaymentBtnLoading" />
          {{ $t("complete_payment") }}
        </CButton>
      </template>
    </CModal>
    <!-- PAYMENT MODAL END  ----->

    <!-- REZERVATİON SETTİNGS   MODAL START  ----->
    <CModal
      close-on-backdrop
      :title="$t('reservation_settings')"
      size="lg"
      :show.sync="reservationSetting.modalShow">
      <v-col class="bil__type" col="12" lg="6">
        <CSelect
          nam="payment-type"
          :value.sync="reservationData.paymentType"
          :options="reservationSetting.typesOfPaymentAvailableOptions"
          :label="$t('payment_type')"
          :placeholder="$t('select_payment_type')" />
      </v-col>
      <v-col class="bil__type" col="12" lg="6" v-if="!isPartner">
        <CSelect
          :value.sync="reservationData.spendingObjection"
          :options="reservationSetting.spendingObjectionOptions"
          :label="$t('spending_objection')"
          :placeholder="$t('select_spending_objection')" />
      </v-col>

      <template #footer>
        <CButton @click="reservationSetting.modalShow = false">
          {{ $t("leave") }}
        </CButton>
        <CButton
          @click="reservationUpdate()"
          :disabled="reservationSetting.modalLoading">
          <font-awesome-icon
            :icon="['fas', 'circle-notch']"
            size="lg"
            class="spin mr-2"
            v-show="reservationSetting.modalLoading" />
          {{ $t("update") }}
        </CButton>
      </template>
    </CModal>
    <!--  REZERVATİON SETTİNGS   MODAL END  ----->

    <!--   REFUND PAYMENT  MODAL START  ----->

    <CModal
      close-on-backdrop
      :title="
        `${reservationData.reservationCode} ` +
        'Numaralı Rezervasyona Ücret İadesi Yapıyorsunuz.'
      "
      size="lg"
      :show.sync="refundPaymentModel"
      v-if="reservationData.carDeliveryReturn">
      <v-tabs v-model="tab" :show-arrows="false">
        <v-tab @click="typeOfRefundPayment = 'PARTIAL'">
          {{ $t("partial_refund_fee_payment") }}
        </v-tab>
        <v-tab @click="typeOfRefundPayment = 'TOTAL'">
          {{ $t("full_refund_fee_payment") }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" class="mt-4">
        <v-tab-item>
          <v-row>
            <v-col>
              <CInput
                :label="$t('refund_payment_amount')"
                class="font-weight-bold"
                v-model.number="partialRefundPayment"
                type="number" />
              <span
                style="color: red; font-weight: bold"
                v-if="
                  !(
                    partialRefundPayment <=
                    reservationData.prices.totalPrice.TRY
                  )
                "
                >Kısmi Ücret Tutarı Tam ücret Tutarından Fazla Olamaz.</span
              >
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item>
          <v-row>
            <v-col>
              <CInput
                :label="$t('refund_payment_amount')"
                class="font-weight-bold"
                :readonly="true"
                v-model.number="reservationData.prices.totalPrice.TRY"
                type="number" />
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>

      <template #footer>
        <CButton
          @click="(refundPaymentModel = false), (partialRefundPayment = 0)">
          {{ $t("leave") }}
        </CButton>

        <CButton
          v-if="partialRefundPayment <= reservationData.prices.totalPrice.TRY"
          color="secondary"
          @click="refundPayment()">
          {{ $t("send") }}
        </CButton>
      </template>
    </CModal>
    <!--   REFUND PAYMENT  MODAL END  ----->

    <!--  NOSHOW REFUND PAYMENT  MODAL START  ----->

    <CModal
      close-on-backdrop
      :title="
        `${reservationData.reservationCode} ` +
        'Numaralı Rezervasyona NoShow Ücret İadesi Yapıyorsunuz.'
      "
      size="lg"
      :show.sync="noShowRefundPaymentModel">
      <v-tabs v-model="tab" :show-arrows="false">
        <v-tab @click="typeOfNoShowRefundPayment = 'PARTIAL'">
          {{ $t("partial_refund_fee_payment") }}
        </v-tab>
        <v-tab @click="typeOfNoShowRefundPayment = 'TOTAL'">
          {{ $t("full_refund_fee_payment") }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" class="mt-4">
        <v-tab-item>
          <v-row>
            <v-col>
              <CInput
                :label="$t('refund_payment_amount')"
                class="font-weight-bold"
                v-model.number="partialNoShowRefundPayment"
                type="number"
                :disabled="typeOfNoShowRefundPayment == `justNoShow`" />
              <span
                style="color: red; font-weight: bold"
                v-if="
                  !(
                    partialNoShowRefundPayment <=
                    reservationData.prices.totalPrice.TRY
                  )
                "
                >Kısmi Ücret Tutarı Tam ücret Tutarından Fazla Olamaz.</span
              >
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item>
          <v-row>
            <v-col>
              <CInput
                :label="$t('refund_payment_amount')"
                class="font-weight-bold"
                :readonly="true"
                v-model.number="reservationData.prices.totalPrice.TRY"
                type="number"
                :disabled="typeOfNoShowRefundPayment == `justNoShow`" />
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
      <v-checkbox
        v-model="typeOfNoShowRefundPayment"
        label="Sadece NoShow"
        color="red"
        value="justNoShow"></v-checkbox>

      <span
        v-if="typeOfNoShowRefundPayment == `justNoShow`"
        style="color: red; font-weight: bold"
        >Rezervasyonun durumu Noshow olarak değiştirilecek ve herhangi bir iade
        ödemesi yapılmayacaktır.<br />
        Müşteriye mail veya sms gönderimi yapılmayacaktır.</span
      >

      <template #footer>
        <CButton
          @click="
            (noShowRefundPaymentModel = false), (partialNoShowRefundPayment = 0)
          ">
          {{ $t("leave") }}
        </CButton>

        <CButton
          v-if="
            partialNoShowRefundPayment <= reservationData.prices.totalPrice.TRY
          "
          color="secondary"
          @click="noShowRefundPayment()">
          {{ $t("send") }}
        </CButton>
      </template>
    </CModal>
    <!--  NOSHOW REFUND PAYMENT  MODAL END  ----->
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import moment, { isDate } from "moment";
import Func from "../../func";
import GogoLoading from "../../components/GogoLoading.vue";
import AgentNote from "@/components/AgentNote";
import Comment from "@/components/Comment";
import { IMaskComponent } from "vue-imask";
import { RoleProvider } from "../../provider";
import DateCheck from "../../components/DateCheck.vue";

var _ = require("lodash");
const defaultPaymentTypes = [
  { label: "Sanal Pos", value: "noneSecurePos" },
  { label: "SMS Linki İle Ödeme", value: "smsPos" },
  { label: "Cari Hesap", value: "debit" },
  // { label: "ivrPos", value: "ivrPos" }, //sonradan gelecek
  // { label: "3dPos", value: "3dPos" },
];
export default {
  name: "ReservationDetail",
  components: {
    AgentNote,
    Comment,
    GogoLoading,
    "imask-input": IMaskComponent,
    RoleProvider,
  },
  data() {
    return {
      tab: null,
      locale: this.$i18n.locale,
      invoiceDetail: {},
      reservationSetting: {
        modalShow: false,
        modalLoading: false,
        typesOfPaymentAvailableOptions: [],
        spendingObjectionOptions: [
          {
            label: this.$t("there_is"),
            value: true,
          },
          {
            label: this.$t("none"),
            value: false,
          },
        ],
        updateBody: {},
      },
      reservationCancellationDate: "",
      //----------payment
      paymentModalShow: false,
      paymentType: "",
      createPaymentBtnLoading: false,

      paymentNoneSecureForm: {
        reservationCode: "",
        cardNumber: "",
        cvv: "",
        expiry: "",
        cardOwner: "",
      },
      //----------payment

      cancelBtnType: "",
      reasonCancel: "",
      otherReasonCancel: "",
      reasonsOfCancel: [],
      statusOfCancellation: false,
      reservastionCancelModal: false,
      refundPaymentModel: false,
      noShowRefundPaymentModel: false,
      reasonEarlyReturn: "",
      openEarlyTextarea: false,
      otherReasonEarlyReturn: "",
      reasonsOfEarlyReturn: [],
      earlyReturnModal: false,
      statusOfEarlyPayment: false,
      earlyReturnDate: null,
      typeOfRefundPayment: "PARTIAL",
      typeOfEarlyReturnPayment: "DAILY",
      typeOfNoShowRefundPayment: "PARTIAL",
      earlyReturnPaymentAmount: 0,
      partialRefundPayment: 0,
      partialNoShowRefundPayment: 0,
      formEarlyReturnRegistrationModal: false,
      bankTransactions: {},
      agentNotesTotal: 0,
      pageLoading: false,
      status: false,
      func: Func,
      ENV_URL_VENDOR: process.env.VUE_APP_CDN_URL_VENDOR,
      ENV_URL_CARS: process.env.VUE_APP_CDN_URL_CARS,
      carCollapsed: true,
      memberCollapsed: true,
      driverCollapsed: true,
      rentalCollapsed: true,
      pricingCollapsed: true,
      reservationCode: this.$route.params.reservationCode,
      reservationDateTime: "",
      locationList: [],
      locationListSelectOptions: [],
      selectedDeliveryLocationSlug: "",
      selectedReturnLocationSlug: "",
      driverInfoPhone: false,
      driverInfoEmail: false,
      driverInfoPhoneForm: "",
      driverInfoEmailForm: "",
      pricing: {
        totalExtrasPrice: 0,
      },

      reservationData: {},
    };
  },
  created() {
    if (this.$route.query.paymentType == "noneSecurePos") {
      this.paymentModalShow = true;
    }
    if (this.reservationCode === "") {
      let routeData = this.$router.resolve({
        name: "Rezervasyonlar",
      });

      window.location.replace(routeData.href);

      return;
    }
    this.reservationGetDetail(); // Rezervasyon detayı çekiliyor
    this.getLocationList(); // Lokasyon listesi çekiliyo r
    this.getReasonsOfCancel();
    this.getReasonsOfEarlyReturn();
    this.setPaymentTypes();
  },

  computed: {
    cancelRezCarInfo() {
      const reservation = this.reservationData;
      // if (reservation?.refund?.status === true &&
      //   ["AGENT_NORMAL", "CUSTOMER_NORMAL", "PARTNER_NORMAL"].includes(reservation?.refund?.place)
      // )

      if (
        reservation?.refund?.status === true &&
        reservation?.status == "cancelled"
      ) {
        const audits = _.find(this.reservationData.audits, {
          type: "STATUS_CANCELLED",
        });
        const refundPlace = this.reservationData.refund.place;
        var refundPlaceName = "";
        if (refundPlace) {
          if (refundPlace == "CUSTOMER_NORMAL") {
            refundPlaceName = "Kullanıcı/Müşteri";
          }
          if (refundPlace == "PARTNER_NORMAL") {
            refundPlaceName = "Partner";
          }
          if (refundPlace == "AGENT_NORMAL") {
            refundPlaceName = "Agent";
          }
        }
        return {
          CardShow: true,
          title: "İPTAL BİLGİLERİ",
          type: "İPTAL",
          person: audits?.place,
          personİnfo: audits?.author?.fullname,
          departmanRole: audits?.departmanRole,
          date: audits?.date,
          place: refundPlaceName,
          message: reservation?.refund?.message,
          refundPrice:
            reservation?.paymentType !== "debit"
              ? _.find(this.reservationData.payments, { paymentType: "refund" })
                  ?.totalAmount || 0
              : 0,
        };
      }
      return {};
    },
    partnerBrand() {
      if (this.reservationData.partnerDetail)
        return this.reservationData.partnerDetail.brand;
    },
    noShowRefundCardInfo() {
      const reservation = this.reservationData;
      if (
        reservation?.noShowRefundPayments?.status === true &&
        ["NOSHOW_REFUND_PAYMENT"].includes(reservation?.refund?.place)
      ) {
        const audits = _.find(this.reservationData.audits, {
          type: "NOSHOW_REFUND_PAYMENT_SUCCESS",
        });
        return {
          CardShow: true,
          title: "NOSHOW İPTAL BİLGİLERİ",
          type: "NOSHOW",
          person: audits?.place,
          personİnfo: audits?.author?.fullname,
          departmanRole: audits?.departmanRole,
          date: audits?.date,
          message: reservation?.refund?.message,
          auditsMessage: audits?.description,
          paymentType: reservation?.paymentType,
          refundPrice:
            reservation?.paymentType !== "debit"
              ? reservation?.payments
                  .reduce(
                    (total, item) =>
                      item.paymentType === "refund"
                        ? total + item.totalAmount
                        : total,
                    0
                  )
                  .toFixed(2)
              : 0,

          reqPrice: reservation?.noShowRefundPayments.price,
        };
      }
      return {};
    },
    refundPayCardInfo() {
      const reservation = this.reservationData;
      if (
        reservation?.refundPayment?.status === true &&
        ["REFUND_PAYMENT"].includes(reservation?.refund?.place)
      ) {
        const audits = _.find(this.reservationData.audits, {
          type: "REFUND_PAYMENT_SUCCESS",
        });
        return {
          CardShow: true,
          title: "İADE ÖDEMESİ BİLGİLERİ",
          type: "İADE ÖDEMESİ",
          person: audits?.place,
          personİnfo: audits?.author?.fullname,
          departmanRole: audits?.departmanRole,
          date: audits?.date,
          message: reservation?.refund?.message,
          auditsMessage: audits?.description,
          paymentType: reservation?.paymentType,
          refundPrice: reservation?.payments
            .reduce(
              (total, item) =>
                item.paymentType === "refund"
                  ? total + item.totalAmount
                  : total,
              0
            )
            .toFixed(2),
          reqPrice: reservation?.refundPayment.price,
        };
      }
      return {};
    },
    EarlyReturnCardInfo() {
      const reservation = this.reservationData;
      let earyDate = moment(reservation.earlyReturn?.date).format("DD-MM-YYYY");
      let oldDate = moment(reservation?.earlyReturn?.oldDate).format(
        "DD-MM-YYYY"
      );
      let DiffTime = Math.abs(
        moment(oldDate, "DD-MM-YYYY").diff(
          moment(earyDate, "DD-MM-YYYY"),
          "days"
        )
      );

      if (["AGENT_EARLY_RETURN"].includes(reservation?.refund?.place)) {
        var auditsReq = _.find(this.reservationData.audits, {
          type: "STATUS_EARLY_RETURN_REQUEST",
        });
        var auditsAdd = _.find(this.reservationData.audits, {
          type: "STATUS_EARLY_RETURN_ADD_INFO",
        });
        var auditsReject = _.find(this.reservationData.audits, {
          type: "STATUS_EARLY_RETURN_REJECTED",
        });
        var auditsSuccess = _.find(this.reservationData.audits, {
          type: "STATUS_EARLY_RETURN_SUCCESS",
        });
        var audits,
          data = {};

        switch (true) {
          case auditsSuccess !== undefined:
            audits = auditsSuccess;
            data.title = "ONAYLANAN ERKEN İADE BİLGİLERİ";
            data.type = "ONAYLANAN ERKEN İADE ";
            break;
          case auditsAdd !== undefined:
            audits = auditsAdd;
            data.title = "ERKEN İADE İSTEĞİ ONAY TALEBİ BİLGİLERİ";
            data.type = "ERKEN İADE İSTEK ONAY TALEBİ";
            break;
          case auditsReq !== undefined:
            audits = auditsReq;
            data.title = "ERKEN İADE TALEBİ BİLGİLERİ";
            data.type = "ERKEN İADE TALEBİ";
            break;
          case auditsReject !== undefined:
            audits = auditsReject;
            data.title = "ERKEN İADE TALEBİ İPTAL BİLGİLERİ";
            data.type = "ERKEN İADE TALEBİ İPTALİ";
            break;
          default:
            break;
        }
        return {
          CardShow: true,
          title: data.title,
          type: data.type,
          DiffTime: DiffTime,
          personİnfo: audits?.author?.fullname,
          departmanRole: audits?.departmanRole,
          date: audits?.date,
          message: reservation?.refund?.message, //
          //refundPrice: reservation?.payments.reduce((total, item) => item?.paymentType === "refund" ? total + item?.totalAmount : total, 0).toFixed(2),
          earlyReturnDate: reservation?.earlyReturn?.date, //
          earlyReturnPayable: reservation?.earlyReturn?.payable, //
        };
      }
      return {};
    },

    resultInstallmentMath() {
      const totalAmount = this.reservationData.payments.find(
        (item) => item.paymentType == "payment"
      )?.totalAmount;
      return totalAmount;
    },
    installmentCount() {
      return (
        this.reservationData.bankTransactions.find(
          (item) => item.type == "paymentSuccess"
        )?.data?.InstallmentCount || ""
      );
    },
    isPartner() {
      return this.$store.state.GetServerData?.role == "PARTNER";
    },
    isPay() {
      return this.reservationData.payments.find(
        (item) => item.paymentType == "payment"
      );
    },
    // earlyRefundAmount() {
    //   this.statusOfEarlyPayment = false;
    //   if (
    //     this.earlyReturnDate == null ||
    //     this.typeOfRefundPayment == null ||
    //     this.earlyReturnPaymentAmount == 0
    //   ) {
    //     this.statusOfEarlyPayment = true;
    //     return 0;
    //   }
    //   var earlyPrice = 0;
    //   var totalDays = this.reservationData.searchPrice.totalDays;
    //   var totalPrice = this.reservationData.prices.totalPrice.TRY;

    //   var returnDate = this.reservationData.carDeliveryReturn.return.datetime;
    //   var returnDateMoment = moment(returnDate).format("MM/DD/YYYY");

    //   var earlyDate = new Date(this.earlyReturnDate);
    //   var earlyDateMoment = moment(earlyDate).format("MM/DD/YYYY");
    //   var end = new Date(returnDateMoment);
    //   var early = new Date(earlyDateMoment);
    //   var diff = Math.abs(end - early);
    //   var days = Math.ceil(diff / (1000 * 60 * 60 * 24));
    //   if (days == 0 || days == totalDays) {
    //     this.statusOfEarlyPayment = true;
    //     return "Geçersiz Tarih";
    //   }
    //   /**-
    //  *   if (this.reservastionErkenIadeSekli == "DAILY") {
    //     earlyPrice =
    //       totalPrice - this.reservastionErkenIadeTutar * (totalDays - days);
    //   } else {
    //     earlyPrice = totalPrice - this.reservastionErkenIadeTutar;
    //   }
    //  */
    //   if (earlyPrice < 0) {
    //     this.statusOfEarlyPayment = true;
    //     return this.$t("invalid_parameters");
    //   }
    //   return earlyPrice + " TL";
    // },
    // reservationCancelData() {
    //   if (typeof this.reservationData.audits === "undefined") {
    //     return {
    //       message: "",
    //       date: "",
    //       place: "",
    //       author: "",
    //       departmanRole: "",
    //     };
    //   }
    //   var audits = _.find(this.reservationData.audits, {
    //     type: "STATUS_CANCELLED",
    //   });

    //   var data = {};
    //   if (typeof audits === "undefined") {
    //     return { message: "", date: "", place: "", author: "" };
    //   }
    //   data = {
    //     message: this.reservationData.refund.message,
    //     date: audits.date,
    //     place: audits.place,
    //     author: audits.author.fullname,
    //     departmanRole: audits.departmanRole,
    //   };

    //   return data;
    // },
    // reservationEarlyData(params) {
    //   if (typeof this.reservationData.audits === "undefined") {
    //     return { message: "", date: "", place: "", author: "" };
    //   }
    //   var audits = _.find(this.reservationData.audits, {
    //     type: "STATUS_EARLY_RETURN_REQUEST",
    //   });

    //   var earlyDate, earlyPrice, totalPrice, earlyDay, totalDay, comment;

    //   earlyDate = this.reservationData.earlyReturn
    //     ? this.reservationData.earlyReturn.date
    //     : null;
    //   earlyPrice = this.reservationData.earlyReturn
    //     ? this.reservationData.earlyReturn.payable
    //     : 0;
    //   totalPrice = this.reservationData.earlyReturn
    //     ? this.reservationData?.earlyReturn?.price?.TOTAL.toFixed(2)
    //     : null;
    //   // totalDay = this.reservationData.earlyReturn
    //   //   ? this.reservationData.earlyReturn.totalDay
    //   //   : 0;
    //   // earlyDay = this.reservationData.searchPrice.totalDays - totalDay;
    //   // if (this.reservationData.earlyReturn) {
    //   //   comment = this.reservationData.earlyReturn.objections
    //   //     ? this.reservationData.earlyReturn.objections[
    //   //     this.reservationData?.earlyReturn?.objections?.length - 1
    //   //     ]
    //   //     : null;
    //   // } else {
    //   //   comment = null;
    //   // }

    //   var data = {
    //     message: this.reservationData.refund.message,
    //     date: audits?.date ? audits?.date : "",
    //     earlyReturnedDate: this.reservationData?.earlyReturn?.date !== null ? this.reservationData.earlyReturn?.date : "-",
    //     place: audits?.place ? audits?.place : "",
    //     author: audits?.author ? audits?.author?.fullname : "",
    //     earlyPrice: earlyPrice?.toFixed(2),
    //     totalPrice,
    //     earlyDate,
    //     totalDay,
    //     earlyDay,
    //     comment,
    //   };
    //   return data;
    // },

    birthdayMoment() {
      if (this.reservationData.driverInfo.birthday) {
        return moment(this.reservationData.driverInfo.birthday).format(
          "DD.MM.YYYY"
        );
      }
    },
    yearMoment() {
      if (this.reservationData.driverInfo.birthday) {
        const dogumYil = new Date(
          this.reservationData.driverInfo.birthday ||
            this.reservationData.driverInfo.birthDate
        ).getFullYear();

        var simdikiYil = moment().format("YYYY");
        var sonuc = Number(simdikiYil) - Number(dogumYil);

        if (_.isNaN(sonuc)) {
          dogumYil = this.reservationData.driverInfo.birthDate.substring(0, 4);
          sonuc = Number(simdikiYil) - Number(dogumYil);
        }

        return sonuc;
      }
    },
    oldPrice() {
      var val;
      if (_.isObject(this.reservationData?.prices?.totalPrice)) {
        val = false;
      } else {
        val = true;
      }
      return val;
    },
    invoicesStatus() {
      var val;
      if (typeof this.reservationData.invoices === "undefined") {
        val = false;
      } else {
        val = true;
      }
      return val;
    },
    phoneFormat() {
      return this.reservationData.driverInfo.phone;
    },
    odenenTutuar: function () {
      var deger = 0;
      if (this.reservationData.bankTransactions.length > 0) {
        deger = parseFloat(this.bankTransactions.TotalAmount);
      } else {
        deger = parseFloat(this.reservationData.prices.payNow[this.currency]);
      }

      return deger;
    },
    odemeDurumu: function () {
      var deger;
      if (this.bankTransactions.InstallmentCount == 1) {
        deger = "Peşin Ödeme";
      } else {
        deger = this.bankTransactions.InstallmentCount + " Taksit";
      }
      return deger;
    },
    extrasSum: function () {
      var deger = _.sumBy(this.reservationData.selectedExtras, (o) => {
        var val;
        if (_.isObject(o?.totalPrice)) {
          val = o?.totalPrice[this.currency];
        } else {
          val = o.totalPrice;
        }

        return val;
      });

      return deger;
    },
    extrasSumCurrency: function () {
      var deger = _.sumBy(this.reservationData.selectedExtras, (o) => {
        var val;
        if (_.isObject(o?.totalPrice)) {
          val = o?.totalPrice[this.currency];
        } else {
          val = o.totalPrice;
        }

        return val;
      });

      return deger;
    },
    currency: function () {
      return this.reservationData.currency.code;
    },
  },
  watch: {
    "$i18n.locale": function (value) {
      window.location.reload();
    },
    reservationData: function (value) {
      if (value.reservationType == "fullCredit") {
        this.reservationData.prices.provision.TRY = 0;
        this.reservationData.prices.dropPrice.TRY = 0;
        this.reservationData.selectedExtras = [];
      }
    },
    driverInfoEmail: function (value) {
      if (value) {
        this.driverInfoEmail = true;
        this.driverInfoEmailForm = this.reservationData.driverInfo.email;
      } else {
        this.driverInfoEmail = false;
      }
    },
    driverInfoPhone: function (value) {
      if (value) {
        this.driverInfoPhone = true;
        this.driverInfoPhoneForm = this.reservationData.driverInfo.phone;
      } else {
        this.driverInfoPhone = false;
      }
    },
  },

  methods: {
    async noShowRefundPayment() {
      var reservationCode = this.reservationCode;
      var typePayment = this.typeOfNoShowRefundPayment;
      var partialPrice =
        typePayment == "TOTAL"
          ? this.reservationData?.prices?.totalPrice?.TRY
          : this.partialNoShowRefundPayment;

      Vue.swal
        .fire({
          title: "Uyarı ?",
          text: `${this.reservationCode}  numaralı rezervasyona NoShow ücret iadesi yapmak istediğinize emin misiniz?`,
          icon: "question",
          showCancelButton: true,
          showCloseButton: true,
          cancelButtonText: this.$t("leave"),
          confirmButtonText: this.$t("i_approve"),
          preConfirm: async () => {
            try {
              await axios.post(
                process.env.VUE_APP_API_URL +
                  "admin/reservation/noshow-payment",
                { partialPrice, typePayment, reservationCode },
                { withCredentialswithCredentials: true }
              );

              this.$store.dispatch({
                type: "successSwal",
                text: "İade ödemesi başarıyla yapıldı",
                refresh: true,
              });
            } catch (error) {
              Vue.swal({
                icon: "error",
                title: this.$t("payment"),
                html: error?.response?.data?.errors[0]?.message,
                confirmButtonText: this.$t("okey"),
              });
            }
          },
          allowOutsideClick: () => !Vue.swal.isLoading(),
        })
        .then((result) => {
          this.alert("Başarılı", "success");
          if (!result.isConfirmed) {
            (this.noShowRefundPaymentModel = false),
              ((this.partialNoShowRefundPayment = 0), (partialPrice = 0));
          }
        });
    },
    async refundPayment() {
      var reservationCode = this.reservationCode;
      var typePayment = this.typeOfRefundPayment;
      var partialPrice =
        typePayment == "TOTAL"
          ? this.reservationData?.prices?.totalPrice?.TRY
          : this.partialRefundPayment;

      Vue.swal
        .fire({
          title: "Uyarı ?",
          text: `${this.reservationCode}  numaralı rezervasyona ücret iadesi yapmak istediğinize emin misiniz?`,
          icon: "question",
          showCancelButton: true,
          showCloseButton: true,
          cancelButtonText: this.$t("leave"),
          confirmButtonText: this.$t("i_approve"),
          preConfirm: async () => {
            try {
              await axios.post(
                process.env.VUE_APP_API_URL +
                  "admin/reservation/refund-payment",
                { partialPrice, typePayment, reservationCode },
                { withCredentialswithCredentials: true }
              );

              this.$store.dispatch({
                type: "successSwal",
                text: "İade ödemesi başarıyla yapıldı",
                refresh: true,
              });
            } catch (error) {
              Vue.swal({
                icon: "error",
                title: this.$t("payment"),
                html: error?.response?.data?.errors[0]?.message,
                confirmButtonText: this.$t("okey"),
              });
            }
          },
          allowOutsideClick: () => !Vue.swal.isLoading(),
        })
        .then((result) => {
          if (!result.isConfirmed) {
            (this.refundPaymentModel = false),
              ((this.partialRefundPayment = 0), (partialPrice = 0));
          }
        });
    },

    invoiceType(data) {
      if (data == "individual") {
        return this.$t("individual");
      } else if (data == "individualCompany") {
        return this.$t("individualCompany");
      } else if (data == "company") {
        return this.$t("company");
      } else if (data == "abroad") {
        return this.$t("abroad");
      }
    },
    redirectComment() {
      let routeData = this.$router.resolve({
        name: this.$t("comments"),

        query: {
          reservationCode: this.reservationData.reservationCode,
        },
      });
      window.open(routeData.href, "_blank");
    },
    setPaymentTypes() {
      const types = this.$store.state.GetServerData.typesOfPaymentAvailable;
      const userType = this.$store.state.GetServerData.userType;
      if (userType == "admin") {
        this.reservationSetting.typesOfPaymentAvailableOptions =
          defaultPaymentTypes;
      } else {
        this.reservationSetting.typesOfPaymentAvailableOptions = types.map(
          (type) => {
            return {
              label: defaultPaymentTypes.find(
                (defaultPayType) => defaultPayType.value == type
              ).label,
              value: type,
            };
          }
        );
      }
    },
    async driverInfoUpdate(type) {
      try {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneRegex = /^\+\d+$/;
        const oldPhone = this.reservationData.driverInfo.phone;
        const oldEmail = this.reservationData.driverInfo.email;
        if (type === "email") {
          this.reservationData.driverInfo.email = this.driverInfoEmailForm;
        } else if (type === "phone") {
          this.reservationData.driverInfo.phone = this.driverInfoPhoneForm;
        }

        if (
          !emailRegex.test(this.reservationData.driverInfo.email) ||
          !phoneRegex.test(this.reservationData.driverInfo.phone)
        ) {
          this.reservationData.driverInfo.email = oldEmail;
          this.reservationData.driverInfo.phone = oldPhone;
          return err;
        }
        let body = {
          _id: this.reservationData._id,
          driverInfo: {
            email: this.reservationData.driverInfo.email,
            phone: this.reservationData.driverInfo.phone,
          },
        };

        await axios.put(
          process.env.VUE_APP_API_URL + "admin/reservation-driver",
          body,
          {
            withCredentials: true,
          }
        );
        this.driverInfoEmail = false;
        this.driverInfoPhone = false;
        Vue.swal({
          title: "Başarılı!",
          icon: "success",
          html: "Sürücü Bilgiler Güncellendi",
        });
      } catch (error) {
        Vue.swal({
          icon: "error",
          title: "Hata!",
          html: "Sürücü Bilgileri Güncellenemedi",
        });
      }
    },
    async reservationUpdate() {
      const changeVariableNames = ["paymentType", "spendingObjection"];
      let body = {
        _id: this.reservationData._id,
      };
      Object.keys(this.reservationData).map((key) => {
        if (changeVariableNames.includes(key)) {
          body[key] = this.reservationData[key];
        }
      });
      this.reservationSetting.modalLoading = true;
      try {
        await axios.put(
          process.env.VUE_APP_API_URL + "admin/reservation",
          body,
          {
            withCredentials: true,
          }
        );
      } catch (error) {
      } finally {
        this.reservationSetting.modalShow = false;
        this.reservationSetting.modalLoading = false;
      }
    },
    earlyReturnRequestButton() {
      if (this.reservationData.consensus.status == "completed") return false;
      if (this.reservationData.isEarlyReturn) return false;
      if (
        this.reservationData.status == "active" ||
        this.reservationData.status == "completed"
      )
        return true;
      else return false;
    },
    cacelBtnClick(type) {
      /**
       * @TYPES
       * one => tek tek
       * all => hepsi
       */
      this.cancelBtnType = type;
      this.reservastionCancelModal = true;
    },
    async makeNoneSecurePayment() {
      this.createPaymentBtnLoading = true;
      const expiry = this.paymentNoneSecureForm.expiry.split("/");
      let body = {
        _id: this.reservationData._id,
        InstallmentCount: 1,
        ExpiryDateYear: expiry[1],
        ExpiryDateMonth: expiry[0],
        Cvv: this.paymentNoneSecureForm.cvv,
        CardNumber: this.paymentNoneSecureForm.cardNumber.split(" ").join(""),
        CardHolderName: this.paymentNoneSecureForm.cardOwner,
        paymentRole: "adminPanel",
        paymentType: "NoneSecure",
      };
      try {
        const result = await axios.post(
          process.env.VUE_APP_API_URL + "payment/make-none-secure",
          body,
          {
            withCredentialswithCredentials: true,
          }
        );
        window.location.href = result.data.url;
      } catch (error) {
        Vue.swal
          .fire({
            icon: "error",
            showCloseButton: true,
            text:
              error.response.data.message ||
              error.response.data.errors[0].message,
          })
          .then((result) => {
            return;
          });
      } finally {
        this.createPaymentBtnLoading = false;
      }
    },
    async getReasonsOfCancel() {
      try {
        let result = await axios.get(
          `${process.env.VUE_APP_API_URL}admin/singular?slug=CANCEL_QUESTIONS`,

          {
            withCredentials: true,
          }
        );

        var items = result.data.data.items;
        _.forEach(items, (item) => {
          if (item.status === "active") {
            this.reasonsOfCancel.push({
              question:
                this.$store.state.locale == "tr" ? item.name : item.name_en,
            });
          }
        });
      } catch (e) {
        this.statusComment = true;
      }
    },
    async getReasonsOfEarlyReturn() {
      try {
        let result = await axios.get(
          `${process.env.VUE_APP_API_URL}admin/singular?slug=EARLY_RETURN_QUESTIONS`,

          {
            withCredentials: true,
          }
        );

        var items = result.data.data.items;
        _.forEach(items, (item) => {
          if (item.status === "active") {
            this.reasonsOfEarlyReturn.push({
              question: item.name,
            });
          }
        });
      } catch (e) {
        this.statusComment = true;
      }
    },

    async surveySend(code) {
      try {
        await axios.post(
          process.env.VUE_APP_API_URL + "admin/comment/" + code,

          {
            withCredentials: true,
          }
        );

        this.$store.dispatch({
          type: "successSwal",
          text: "Anket başarılı bir şekilde telefon ve e-posta adresine gönderildi",
          refresh: false,
        });
      } catch (error) {}
    },

    confirmEarlyReturn() {
      Vue.swal
        .fire({
          icon: "question",
          title: this.$t("early_return_confirmation"),

          showCancelButton: true,
          cancelButtonText: this.$t("leave"),
          confirmButtonText: this.$t("i_approve"),
          showCloseButton: true,
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            try {
              await axios.post(
                process.env.VUE_APP_API_URL +
                  "admin/reservation/early-return/success/" +
                  this.reservationData.reservationCode,

                {
                  withCredentials: true,
                }
              );

              this.$store.dispatch({
                type: "successSwal",
                text: this.$t("early_return_confirmed"),
                refresh: true,
              });
            } catch (error) {
              Vue.swal({
                icon: "error",
                title: this.$t("reservation"),
                html: error.response.data.errors[0].message,
                confirmButtonText: this.$t("okey"),
              });
            }
          },

          allowOutsideClick: () => !Vue.swal.isLoading(),
        })
        .then((result) => {
          if (result.isConfirmed) {
          }
        });
    },

    rejectEarlyReturn() {
      Vue.swal
        .fire({
          icon: "question",
          title: this.$t("early_return_rejection"),
          input: "textarea",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          cancelButtonText: this.$t("leave"),
          confirmButtonText: this.$t("i_approve"),
          showCloseButton: true,
          showLoaderOnConfirm: true,
          preConfirm: async (objection) => {
            try {
              await axios.post(
                process.env.VUE_APP_API_URL +
                  "admin/reservation/early-return/reject/" +
                  this.reservationData.reservationCode,
                {
                  objection,
                },
                {
                  withCredentials: true,
                }
              );

              this.$store.dispatch({
                type: "successSwal",
                text: this.$t("early_return_denied"),
                refresh: true,
              });
            } catch (error) {
              Vue.swal({
                icon: "error",
                title: this.$t("reservation"),
                html: error.response.data.errors[0].message,
                confirmButtonText: this.$t("okey"),
              });
            }
          },

          allowOutsideClick: () => !Vue.swal.isLoading(),
        })
        .then((result) => {
          if (result.isConfirmed) {
          }
        });
    },

    async earlyReturnRegistration() {
      this.formEarlyReturnRegistrationModal = false;

      var price = parseFloat(this.earlyReturnPaymentAmount);
      // var date = new Date(this.earlyReturnDate).toISOString();
      var date =
        moment(this.earlyReturnDate).format("YYYY-MM-DD") +
        "T" +
        moment(this.earlyReturnDate).format("HH:mm") +
        ":00Z";
      try {
        await axios.post(
          process.env.VUE_APP_API_URL +
            "admin/reservation/early-return/add-info/" +
            this.reservationData.reservationCode,
          {
            price,
            date,
          },
          {
            withCredentials: true,
          }
        );

        this.$store.dispatch({
          type: "successSwal",
          text: this.$t("early_return_register_info"),
          refresh: true,
        });
      } catch (error) {
        Vue.swal({
          icon: "error",
          title: this.$t("reservation"),
          html: error.response.data.errors[0].message,
          confirmButtonText: this.$t("okey"),
        });
      }
    },

    async earlyReturnRequest() {
      this.earlyReturnModal = false;

      var message = this.reasonEarlyReturn;

      try {
        await axios.post(
          process.env.VUE_APP_API_URL +
            "admin/reservation/early-return/request/" +
            this.reservationData.reservationCode,
          {
            message,
          },
          {
            withCredentials: true,
          }
        );

        this.$store.dispatch({
          type: "successSwal",
          text: this.$t("early_return_register_info"),
          refresh: true,
        });
      } catch (error) {
        Vue.swal({
          icon: "error",
          title: this.$t("reservation"),
          html: error.response.data.errors[0].message,
          confirmButtonText: this.$t("okey"),
        });
      }
    },

    getLocationList() {
      var _this = this;

      axios
        .get(process.env.VUE_APP_API_URL + "admin/location?status=true")
        .then((response) => {
          response.data.locationList.forEach((e) => {
            _this.locationList.push({
              group: e.city,
              name: e.locationName,
              slug: e.slug,
            });

            _this.locationListSelectOptions.push({
              label: e.city + " - " + e.locationName,
              value: e.slug,
            });
          });
        });
    },

    reservationGetDetail() {
      var _this = this;
      this.$Progress.start();

      axios
        .get(process.env.VUE_APP_API_URL + "admin/reservation", {
          params: {
            reservationCode: this.reservationCode,
          },
          withCredentials: true,
          headers: {
            lang: _this.$store.state.locale || "tr",
          },
        })
        .then((response) => {
          this.$Progress.finish();
          let data = response.data.reservations[0];
          // Rezervasyon tarihi sayfada gösterilmek için format işlemi yapılıyor
          this.reservationDateTime = moment(data.reservationDateTime).format(
            "DD.MM.YYYY HH:mm"
          );

          data.audits
            .filter((element) => element.type == "STATUS_CANCELLED")
            .map((element) => {
              this.reservationCancellationDate = element.date;
            });
          this.reservationData = data;
          if (this.$store.state.locale === "en") {
            this.reservationData.carDeliveryReturn.delivery.name =
              this.reservationData?.carDeliveryReturn?.delivery?.nameEn?.locationNameEn;
            this.reservationData.carDeliveryReturn.return.name =
              this.reservationData?.carDeliveryReturn?.return?.nameEn?.locationNameEn;
          }
          this.invoiceDetail = {
            invoiceType: this.invoiceType(data.invoices?.invoiceType),
            address:
              data.invoices[data.invoices.invoiceType]?.address +
              " " +
              data.invoices[data.invoices.invoiceType]?.province +
              " " +
              data.invoices[data.invoices.invoiceType]?.postCode,
            taxNumber: data.invoices[data.invoices.invoiceType]?.taxNumber,
            taxOffice: data.invoices[data.invoices.invoiceType]?.taxOffice,
            title:
              data.invoices[data.invoices.invoiceType]?.title ||
              data.invoices[data.invoices.invoiceType]?.firstname +
                " " +
                data.invoices[data.invoices.invoiceType]?.lastname,

            invoiceNumber: data.ERP?.invoiceNumber,
            invoiceTransferStatus: data.ERP?.invoiceTransferStatus,
            invoiceERPMessage: data.ERP?.invoiceERPMessage,
            invoiceDate: data.ERP?.invoiceDate,
          };

          _.forEach(this.reservationData.bankTransactions, (result) => {
            if (result.type === "paymentSuccess") {
              this.bankTransactions = result.data;
            }
          });
          this.status = true;
          this.pageLoading = true;
          this.getAgentNotesTotal(this.reservationData._id);
        });
    },

    reservationPdfDownload(email, code) {
      window.open(
        process.env.VUE_APP_API_URL + "getReservationPdf/" + code,
        "_blank"
      );
    },

    async beginIVR(reservationCode) {
      try {
        // ? Environments.
        const { VUE_APP_API_URL } = process.env;

        // ? Cağrıyı IVR yönlendirir.
        await axios.post(
          `${VUE_APP_API_URL}admin/alotech/ivr/click-two-transfer/${reservationCode}`,
          undefined,
          {
            withCredentials: true,
          }
        );

        Vue.swal.fire({
          icon: "success",
          text: "Cağrınız ivr yönlendirildi.",
          showCancelButton: false,
        });
      } catch (error) {
        Vue.swal.fire({
          icon: "error",
          text: "İşlem Başarısız!",
          showCancelButton: false,
        });
      }

      // var params = {
      //   id,
      // };

      // try {
      //   await axios.post(
      //     process.env.VUE_APP_API_URL + "admin/reservation/success-resend-mail",
      //     params,
      //     {
      //       withCredentials: true,
      //     }
      //   );
      //   let timerInterval;
      //   Vue.swal
      //     .fire({
      //       icon: "success",
      //       allowOutsideClick: false,
      //       allowEscapeKey: false,
      //       allowEnterKey: false,
      //       text: "Onay E-postası başarılı bir şekilde gönderilmiştir",
      //       timer: 2000,
      //       timerProgressBar: true,
      //       showConfirmButton: false,
      //       willClose: () => {
      //         clearInterval(timerInterval);
      //       },
      //     })
      //     .then((result) => {
      //       if (result.dismiss === Vue.swal.DismissReason.timer) {
      //       }
      //     });
      // } catch (error) {
      //   Vue.swal.fire({
      //     icon: "error",
      //     text: error.response.data.message,
      //     showCancelButton: false,
      //   });

      // }
    },

    async reservationEmailSend(id) {
      //
      var params = {
        id,
      };

      try {
        await axios.post(
          process.env.VUE_APP_API_URL + "admin/reservation/success-resend-mail",
          params,
          {
            withCredentials: true,
          }
        );
        let timerInterval;
        Vue.swal
          .fire({
            icon: "success",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            text: this.$t("approve_email_desc"),
            timer: 2000,
            timerProgressBar: true,
            showConfirmButton: false,
            willClose: () => {
              clearInterval(timerInterval);
            },
          })
          .then((result) => {
            if (result.dismiss === Vue.swal.DismissReason.timer) {
            }
          });
      } catch (error) {
        Vue.swal.fire({
          icon: "error",
          text: error.response.data.message,
          showCancelButton: false,
        });
      }
    },

    async reservationStatusUpdate(status) {
      try {
        var refundMessage = "";
        if (this.reasonCancel == 1) {
          refundMessage = this.otherReasonCancel;
        } else {
          refundMessage = this.reasonCancel;
        }
        if (!refundMessage.length) {
          Vue.swal({
            icon: "error",
            title: this.$t("reservation"),
            html: this.$t("warning.cancel_empty"),
            confirmButtonText: this.$t("okey"),
          });
          return;
        }
        this.reservastionCancelModal = false;

        Vue.swal.fire({
          icon: "warning",
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          text: this.$t("wait"),
        });
        const reservationStatusUpdate = await axios.patch(
          `${process.env.VUE_APP_API_URL}admin/reservation/status/${this.reservationData._id}/${status}`,
          { refundMessage },
          {
            withCredentials: true,
            headers: {
              lang: this.$store.state.locale || "tr",
            },
          }
        );

        this.$store.dispatch({
          type: "successSwal",
          text: this.$t("cancel_success_info"),
          refresh: true,
        });
      } catch (error) {
        Vue.swal({
          icon: "error",
          title: this.$t("reservation"),
          html: error.response.data.errors[0].message,
          confirmButtonText: this.$t("okey"),
        });
      }
    },
    async getAgentNotesTotal(id) {
      try {
        let agentNotes = await axios.get(
          process.env.VUE_APP_API_URL + "admin/note?place._id=" + id,
          {
            withCredentials: true,
          }
        );
        this.agentNotesTotal = agentNotes.data.length;
      } catch (error) {}
    },
  },
};
</script>

<style lang="scss">
.v-window {
  overflow: visible;
}

.down-btn {
  .v-btn__content {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
  }
}

.cancel-btn {
  button {
    background-color: red !important;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

.approved {
  color: #2eb85c;
}

.pending,
.suspended {
  color: #f9b115;
}

.cancelled {
  color: #e55353;
}

.approved,
.pending,
.suspended,
.cancelled {
  font-weight: bold;
  margin-right: 5px;
}
</style>

<!-- ***=>TEMPLATE<=***

=>>REZERVASYON DETAY CARD
=>>SÜRÜCÜ BİLGİLERİ CARD 
=>>FATURA BİLGİLERİ CARD 
=>>ÖDEME BİLGİLERİ CARD 
=>>GENEL BİLGİLER CARD 
=>>ÖDEMELER CARD 
=>>POS HAREKETLERİ CARD 
=>>REZERVASYON ANKETİ CARD 
=>>ERKEN İADE BİLGİLERİ CARD 
=>>ERKEN İADE BİLGİLERİ 2  CARD 

=>>REZERVATİON CANCELLATION MODAL
=>>EARLY RETURN  MODAL
=>>EARLY  RETURN REGISTRATION FORM
=>>PAYMENT MODAL 
=>>REZERVATİON SETTİNGS   MODAL
=>>REFUND PAYMENT  MODAL -->
