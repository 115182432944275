var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.pageLoading
        ? _c(
            "div",
            { attrs: { fluid: "" } },
            [
              _vm.reservationData.spendingObjection
                ? _c(
                    "CAlert",
                    { staticClass: "mt-3", attrs: { color: "danger" } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("description.spending_objection")) +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.reservationData.paymentType == "noneSecurePos" &&
              !_vm.isPay &&
              !_vm.reservationData.vendorReservationCode
                ? _c(
                    "CAlert",
                    { staticClass: "mt-3", attrs: { color: "warning" } },
                    [
                      _c("strong", [_vm._v(" " + _vm._s(_vm.$t("caution")))]),
                      _vm._v(
                        " " + _vm._s(_vm.$t("description.spending_desc")) + " "
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", lg: "5", md: "6", sm: "12" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "mb-5", attrs: { elevation: "10" } },
                        [
                          _c(
                            "v-toolbar",
                            {
                              attrs: {
                                height: "40",
                                dense: "",
                                dark: "",
                                color: "grey darken-1",
                                elevation: "0",
                              },
                            },
                            [
                              _c(
                                "v-toolbar-title",
                                { staticStyle: { "font-size": "14px" } },
                                [
                                  _c("strong", [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("reservation")) + " "
                                    ),
                                  ]),
                                  _vm._v(
                                    " #" +
                                      _vm._s(
                                        _vm.reservationData.reservationCode
                                      )
                                  ),
                                ]
                              ),
                              _c("v-spacer"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.reservationData.searchPrice.car.brand
                                  ) +
                                  " " +
                                  _vm._s(
                                    _vm.reservationData.searchPrice.car.model
                                  ) +
                                  " "
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "align-items-center justify-start",
                                  attrs: { cols: "12", md: "6", sm: "12" },
                                },
                                [
                                  _c("v-img", {
                                    attrs: {
                                      height: "70",
                                      contain: "",
                                      src:
                                        _vm.ENV_URL_CARS +
                                        _vm.reservationData.searchPrice.car
                                          .image,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "p-3 d-flex align-items-center",
                                  attrs: { cols: "12", md: "6", sm: "12" },
                                },
                                [
                                  _c("v-img", {
                                    attrs: {
                                      src:
                                        _vm.ENV_URL_VENDOR +
                                        _vm.reservationData.searchPrice.vendor
                                          .logo,
                                      "max-height": "70",
                                      contain: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "ml-1",
                                  attrs: { sm: "12", md: "12" },
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass:
                                        "align-items-center justify-content-center pa-0",
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pa-0",
                                          attrs: {
                                            cols: "6",
                                            sm: "6",
                                            md: "3",
                                          },
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("class")) +
                                                " "
                                            ),
                                          ]),
                                          _c("p", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$store.state.ceviri[
                                                    _vm.reservationData
                                                      .searchPrice.car.class
                                                  ]
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "p-0",
                                          attrs: {
                                            cols: "6",
                                            sm: "6",
                                            md: "3",
                                          },
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v(
                                              " " + _vm._s(_vm.$t("gear")) + " "
                                            ),
                                          ]),
                                          _c("p", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$store.state.ceviri[
                                                    _vm.reservationData
                                                      .searchPrice.car
                                                      .transmission
                                                  ]
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "p-0",
                                          attrs: {
                                            cols: "6",
                                            sm: "6",
                                            md: "3",
                                          },
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v(
                                              " " + _vm._s(_vm.$t("fuel")) + " "
                                            ),
                                          ]),
                                          _c("p", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$store.state.ceviri[
                                                    _vm.reservationData
                                                      .searchPrice.car.fuel
                                                  ]
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "p-0",
                                          attrs: {
                                            cols: "6",
                                            sm: "6",
                                            md: "3",
                                          },
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v(
                                              " " + _vm._s(_vm.$t("seat")) + " "
                                            ),
                                          ]),
                                          _c("p", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.reservationData
                                                    .searchPrice.car.seat
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "p-0",
                                          attrs: {
                                            cols: "6",
                                            sm: "6",
                                            md: "3",
                                          },
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("km_limit")) +
                                                " "
                                            ),
                                          ]),
                                          _c("p", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.reservationData
                                                    .searchPrice.rules
                                                    .dailyRangeLimit === 0
                                                    ? "Sınırsız"
                                                    : _vm.reservationData
                                                        .searchPrice.rules
                                                        .totalRangeLimit + " KM"
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "p-0",
                                          attrs: {
                                            cols: "6",
                                            sm: "6",
                                            md: "3",
                                          },
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v(
                                              " " + _vm._s(_vm.$t("age")) + " "
                                            ),
                                          ]),
                                          _c("p", [
                                            _vm._v(
                                              " + " +
                                                _vm._s(
                                                  _vm.reservationData
                                                    .searchPrice.rules.driverAge
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "p-0",
                                          attrs: {
                                            cols: "6",
                                            sm: "6",
                                            md: "3",
                                          },
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("driving_license")
                                                ) +
                                                " "
                                            ),
                                          ]),
                                          _c("p", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.reservationData
                                                    .searchPrice.rules
                                                    .licenseYears
                                                ) +
                                                " " +
                                                _vm._s(_vm.$t("year")) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "p-0",
                                          attrs: {
                                            cols: "6",
                                            sm: "6",
                                            md: "3",
                                          },
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v(
                                              " " + _vm._s(_vm.$t("fuel")) + " "
                                            ),
                                          ]),
                                          _c("p", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$store.state.ceviri[
                                                    _vm.reservationData
                                                      .searchPrice.car
                                                      .fuelPolicy
                                                  ]
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-divider", { staticClass: "m-0" }),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { col: "10" } },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pa-0 ma-0 pl-1",
                                          attrs: { col: "4" },
                                        },
                                        [
                                          _c(
                                            "h5",
                                            {
                                              staticStyle: {
                                                "font-weight": "900",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    title: "Alış Lokasyonu",
                                                  },
                                                },
                                                [_vm._v(" mdi-arrow-up-box ")]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.reservationData
                                                      .carDeliveryReturn
                                                      .delivery.name
                                                  ) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-items-center mt-3",
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  icon: ["fas", "calendar-alt"],
                                                  size: "lg",
                                                },
                                              }),
                                              _c(
                                                "p",
                                                { staticClass: "h6 m-0" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f("moment")(
                                                          _vm.reservationData
                                                            .carDeliveryReturn
                                                            .delivery.datetime,
                                                          "Do MMMM YYYY, dddd HH:mm"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-items-center mt-3",
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  icon: ["fas", "info-circle"],
                                                  size: "lg",
                                                },
                                              }),
                                              _c(
                                                "p",
                                                { staticClass: "h6 m-0" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$store.state.ceviri[
                                                          _vm.reservationData
                                                            .searchPrice.office
                                                            .deliveryType
                                                        ]
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "p",
                                            { staticClass: "m-0 mt-1 small" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.reservationData
                                                      .searchPrice.office
                                                      .address
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c("v-divider", {
                                        attrs: { vertical: "" },
                                      }),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pa-0 ma-0 pl-5",
                                          attrs: { col: "6" },
                                        },
                                        [
                                          _c(
                                            "h5",
                                            {
                                              staticStyle: {
                                                "font-weight": "900",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    title: "İade Lokasyonu",
                                                  },
                                                },
                                                [_vm._v(" mdi-arrow-down-box ")]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.reservationData
                                                      .carDeliveryReturn.return
                                                      .name
                                                  ) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-items-center mt-3",
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  icon: ["fas", "calendar-alt"],
                                                  size: "lg",
                                                },
                                              }),
                                              _vm.reservationData.status !==
                                              `confirmedEarlyReturn`
                                                ? _c(
                                                    "p",
                                                    { staticClass: "h6 m-0" },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm._f("moment")(
                                                              _vm
                                                                .reservationData
                                                                .carDeliveryReturn
                                                                .return
                                                                .datetime,
                                                              "Do MMMM YYYY, dddd HH:mm"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.reservationData.status ==
                                              `confirmedEarlyReturn`
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "h6 m-0 text-decoration-line-through",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm._f("moment")(
                                                              _vm
                                                                .reservationData
                                                                .earlyReturn
                                                                ?.oldDate,
                                                              "Do MMMM YYYY, dddd HH:mm"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _vm.EarlyReturnCardInfo.CardShow ==
                                          true
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex align-items-center mt-3",
                                                },
                                                [
                                                  _c("font-awesome-icon", {
                                                    staticClass:
                                                      "mr-2 red--text",
                                                    attrs: {
                                                      icon: [
                                                        "fas",
                                                        "calendar-alt",
                                                      ],
                                                      size: "lg",
                                                    },
                                                  }),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "h6 m-0 red--text font-weight-bold",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm._f("moment")(
                                                              _vm
                                                                .EarlyReturnCardInfo
                                                                .earlyReturnDate,
                                                              "Do MMMM YYYY, dddd HH:mm"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-items-center mt-3",
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  icon: ["fas", "info-circle"],
                                                  size: "lg",
                                                },
                                              }),
                                              _c(
                                                "p",
                                                { staticClass: "h6 m-0" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$store.state.ceviri[
                                                          _vm.reservationData
                                                            .searchPrice
                                                            .returnOffice
                                                            .deliveryType
                                                        ]
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "p",
                                            { staticClass: "m-0 mt-1 small" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.reservationData
                                                      .searchPrice.returnOffice
                                                      .address
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-divider", { staticClass: "m-0" }),
                          _vm.reservationData.searchPrice.campaignData != null
                            ? _c("div", { staticClass: "pt-2 ml-3 mr-3" }, [
                                _c("div", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("campaign")) + " :"),
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.reservationData.searchPrice
                                          .campaignData.info.name.tr
                                          ? _vm.reservationData.searchPrice
                                              .campaignData.info.name.tr
                                          : _vm.reservationData.searchPrice
                                              .campaignData.info.name || ""
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("div", [
                                  _c("div", { staticClass: "pr-3" }, [
                                    _c("strong", [
                                      _vm._v(_vm._s(_vm.$t("discount")) + " :"),
                                    ]),
                                    _vm._v(
                                      " %" +
                                        _vm._s(
                                          _vm.reservationData.searchPrice
                                            .campaignData.discountRate ||
                                            _vm.reservationData.searchPrice
                                              .campaignData.discountAmount
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.reservationData.searchPrice.campaignData != null
                            ? _c("v-divider", { staticClass: "m-0" })
                            : _vm._e(),
                          _c("v-simple-table", { attrs: { dense: "" } }, [
                            _c(
                              "tbody",
                              [
                                _c("tr", [
                                  _c("td", [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(_vm.$t("daily_rental_fee"))
                                      ),
                                    ]),
                                    _vm.reservationData?.prices
                                      ?.dailyRentalPrice.TRY >
                                    _vm.reservationData?.prices
                                      ?.retailDailyRentalPrice?.TRY
                                      ? _c("span", [
                                          _c("br"),
                                          _c("small", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("retailDailyRentalPrice")
                                              )
                                            ),
                                          ]),
                                        ])
                                      : _vm._e(),
                                  ]),
                                  _c("td", { staticClass: "text-right" }, [
                                    _c(
                                      "div",
                                      { staticClass: "font-weight-bold" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.func
                                                .toMoneyFormat(
                                                  _vm.reservationData?.prices
                                                    ?.dailyRentalPrice[
                                                    _vm.reservationData.currency
                                                      .code
                                                  ]
                                                )
                                                .toLocaleString(
                                                  _vm.reservationData.lang,
                                                  {
                                                    style: "currency",
                                                    currency: [
                                                      _vm.reservationData
                                                        .currency.code,
                                                    ],
                                                  }
                                                )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _vm.reservationData?.prices
                                      ?.dailyRentalPrice.TRY >
                                    _vm.reservationData?.prices
                                      ?.retailDailyRentalPrice.TRY
                                      ? _c("div", [
                                          _c("small", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.func
                                                    .toMoneyFormat(
                                                      _vm.reservationData
                                                        ?.prices
                                                        ?.retailDailyRentalPrice[
                                                        _vm.reservationData
                                                          .currency.code
                                                      ]
                                                    )
                                                    .toLocaleString(
                                                      _vm.reservationData.lang,
                                                      {
                                                        style: "currency",
                                                        currency: [
                                                          _vm.reservationData
                                                            .currency.code,
                                                        ],
                                                      }
                                                    )
                                                )
                                            ),
                                          ]),
                                        ])
                                      : _vm._e(),
                                  ]),
                                ]),
                                _c("tr", [
                                  _c("td", [
                                    _c("strong", [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("rental_price")) +
                                          " "
                                      ),
                                    ]),
                                    _vm.reservationData.prices.totalRentalPrice
                                      .TRY >
                                    _vm.reservationData.prices
                                      .retailTotalRentalPrice.TRY
                                      ? _c("span", [
                                          _c("br"),
                                          _c("small", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("retailTotalRentalPrice")
                                              )
                                            ),
                                          ]),
                                        ])
                                      : _vm._e(),
                                  ]),
                                  _c("td", { staticClass: "text-right" }, [
                                    _c("span", [
                                      _c(
                                        "div",
                                        { staticClass: "font-weight-bold" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.func
                                                  .toMoneyFormat(
                                                    _vm.reservationData?.prices
                                                      ?.totalRentalPrice[
                                                      _vm.reservationData
                                                        .currency.code
                                                    ]
                                                  )
                                                  .toLocaleString(
                                                    _vm.reservationData.lang,
                                                    {
                                                      style: "currency",
                                                      currency: [
                                                        _vm.reservationData
                                                          .currency.code,
                                                      ],
                                                    }
                                                  )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _vm.reservationData.prices
                                        .totalRentalPrice.TRY >
                                      _vm.reservationData.prices
                                        .retailTotalRentalPrice.TRY
                                        ? _c("div", [
                                            _c("small", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.func
                                                      .toMoneyFormat(
                                                        _vm.reservationData
                                                          ?.prices
                                                          ?.retailTotalRentalPrice[
                                                          _vm.reservationData
                                                            .currency.code
                                                        ]
                                                      )
                                                      .toLocaleString(
                                                        _vm.reservationData
                                                          .lang,
                                                        {
                                                          style: "currency",
                                                          currency: [
                                                            _vm.reservationData
                                                              .currency.code,
                                                          ],
                                                        }
                                                      )
                                                  )
                                              ),
                                            ]),
                                          ])
                                        : _vm._e(),
                                    ]),
                                  ]),
                                ]),
                                _vm.reservationData.prices?.totalExtraPrice[
                                  _vm.reservationData.currency.code
                                ] > 0
                                  ? _vm._l(
                                      _vm.reservationData.selectedExtras,
                                      function (data, key) {
                                        return _c("tr", { key: key }, [
                                          _c(
                                            "td",
                                            { staticStyle: { color: "gray" } },
                                            [
                                              _c(
                                                "strong",
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      " mdi-chevron-right "
                                                    ),
                                                  ]),
                                                  _vm._v(
                                                    " " + _vm._s(data.name)
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticClass: "text-right",
                                              staticStyle: { color: "gray" },
                                            },
                                            [
                                              _c("span", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "font-weight-bold",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.func
                                                            .toMoneyFormat(
                                                              data.totalPrice
                                                            )
                                                            [
                                                              _vm
                                                                .reservationData
                                                                ?.currency?.code
                                                            ].toLocaleString(
                                                              _vm
                                                                .reservationData
                                                                .lang,
                                                              {
                                                                style:
                                                                  "currency",
                                                                currency: [
                                                                  _vm
                                                                    .reservationData
                                                                    .currency
                                                                    .code,
                                                                ],
                                                              }
                                                            )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _vm.reservationData.currency
                                                  .code != "TRY"
                                                  ? _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.func
                                                              .toMoneyFormat(
                                                                data.totalPrice[
                                                                  _vm
                                                                    .reservationData
                                                                    .currency
                                                                    .code
                                                                ]
                                                              )
                                                              .toLocaleString(
                                                                _vm
                                                                  .reservationData
                                                                  .lang,
                                                                {
                                                                  style:
                                                                    "currency",
                                                                  currency: [
                                                                    _vm
                                                                      .reservationData
                                                                      .currency
                                                                      .code,
                                                                  ],
                                                                }
                                                              )
                                                          ) +
                                                          " "
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                              ]),
                                            ]
                                          ),
                                        ])
                                      }
                                    )
                                  : _vm._e(),
                                _vm.reservationData.prices?.totalExtraPrice[
                                  _vm.reservationData.currency.code
                                ] > 0
                                  ? _c("tr", [
                                      _c("td", [
                                        _c("strong", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("total_extra_fee")
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]),
                                      _c("td", { staticClass: "text-right" }, [
                                        _c(
                                          "div",
                                          { staticClass: "font-weight-bold" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.func
                                                    .toMoneyFormat(
                                                      _vm.reservationData.prices
                                                        ?.totalExtraPrice[
                                                        _vm.reservationData
                                                          .currency.code
                                                      ]
                                                    )
                                                    .toLocaleString(
                                                      _vm.reservationData.lang,
                                                      {
                                                        style: "currency",
                                                        currency: [
                                                          _vm.reservationData
                                                            .currency.code,
                                                        ],
                                                      }
                                                    )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm.reservationData.prices.dropPrice.EUR > 0
                                  ? _c("tr", [
                                      _c("td", [
                                        _c(
                                          "strong",
                                          [
                                            _c("v-icon", [
                                              _vm._v(" mdi-chevron-right "),
                                            ]),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("drop_off_price")
                                                ) +
                                                " "
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                      _c("td", { staticClass: "text-right" }, [
                                        _c(
                                          "div",
                                          { staticClass: "font-weight-bold" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.func
                                                    .toMoneyFormat(
                                                      _vm.reservationData
                                                        ?.prices?.dropPrice[
                                                        _vm.reservationData
                                                          .currency.code
                                                      ]
                                                    )
                                                    .toLocaleString(
                                                      _vm.reservationData.lang,
                                                      {
                                                        style: "currency",
                                                        currency: [
                                                          _vm.reservationData
                                                            .currency.code,
                                                        ],
                                                      }
                                                    )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _c("tr", [
                                  _c("td", [
                                    _c("strong", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("amount_payable_on_delivery")
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]),
                                  _c("td", { staticClass: "text-right" }, [
                                    _c("span", [
                                      _c(
                                        "div",
                                        { staticClass: "font-weight-bold" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.func
                                                  .toMoneyFormat(
                                                    _vm.reservationData?.prices
                                                      ?.payLater[
                                                      _vm.reservationData
                                                        .currency.code
                                                    ]
                                                  )
                                                  .toLocaleString(
                                                    _vm.reservationData.lang,
                                                    {
                                                      style: "currency",
                                                      currency: [
                                                        _vm.reservationData
                                                          .currency.code,
                                                      ],
                                                    }
                                                  )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ]),
                                _c("tr", [
                                  _c("td", [
                                    _c("strong", [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("total_fee")) + " "
                                      ),
                                    ]),
                                    _c("small", [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("excluding_deposit")) +
                                          " "
                                      ),
                                    ]),
                                  ]),
                                  _c("td", { staticClass: "text-right" }, [
                                    _vm.oldPrice
                                      ? _c("span", [
                                          _c(
                                            "div",
                                            { staticClass: "font-weight-bold" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.func.toMoneyFormat(
                                                      (_vm.reservationData?.prices?.totalPrice[
                                                        _vm.reservationData
                                                          .currency.code
                                                      ]).toLocaleString(
                                                        _vm.reservationData
                                                          .lang,
                                                        {
                                                          style: "currency",
                                                          currency: [
                                                            _vm.reservationData
                                                              .currency.code,
                                                          ],
                                                        }
                                                      )
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _c("span", [
                                          _c(
                                            "div",
                                            { staticClass: "font-weight-bold" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.func.toMoneyFormat(
                                                      (_vm.reservationData?.prices?.totalPrice[
                                                        _vm.reservationData
                                                          .currency.code
                                                      ]).toLocaleString(
                                                        _vm.reservationData
                                                          .lang,
                                                        {
                                                          style: "currency",
                                                          currency: [
                                                            _vm.reservationData
                                                              .currency.code,
                                                          ],
                                                        }
                                                      )
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]),
                                  ]),
                                ]),
                                _vm.reservationData.reservationType !=
                                "fullCredit"
                                  ? _c("tr", [
                                      _c(
                                        "td",
                                        { staticStyle: { color: "gray" } },
                                        [
                                          _c("strong", [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("deposit")) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticClass: "text-right",
                                          staticStyle: { color: "gray" },
                                        },
                                        [
                                          _vm.oldPrice
                                            ? _c("span", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "font-weight-bold",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.func
                                                            .toMoneyFormat(
                                                              _vm
                                                                .reservationData
                                                                ?.prices
                                                                ?.provision[
                                                                _vm
                                                                  .reservationData
                                                                  .currency.code
                                                              ]
                                                            )
                                                            .toLocaleString(
                                                              _vm
                                                                .reservationData
                                                                .lang,
                                                              {
                                                                style:
                                                                  "currency",
                                                                currency: [
                                                                  _vm
                                                                    .reservationData
                                                                    .currency
                                                                    .code,
                                                                ],
                                                              }
                                                            )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ])
                                            : _c("span", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "font-weight-bold",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.func
                                                            .toMoneyFormat(
                                                              _vm
                                                                .reservationData
                                                                ?.prices
                                                                ?.provision[
                                                                _vm
                                                                  .reservationData
                                                                  .currency.code
                                                              ]
                                                            )
                                                            .toLocaleString(
                                                              _vm
                                                                .reservationData
                                                                .lang,
                                                              {
                                                                style:
                                                                  "currency",
                                                                currency: [
                                                                  _vm
                                                                    .reservationData
                                                                    .currency
                                                                    .code,
                                                                ],
                                                              }
                                                            )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-0",
                      attrs: { cols: "12", lg: "7", md: "6", sm: "12" },
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12",
                                lg: "7",
                                md: "12",
                                sm: "12",
                              },
                            },
                            [
                              _c(
                                "v-card",
                                { attrs: { elevation: "10" } },
                                [
                                  _c(
                                    "v-toolbar",
                                    {
                                      attrs: {
                                        height: "40",
                                        dense: "",
                                        dark: "",
                                        color: "grey darken-1",
                                        elevation: "0",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-toolbar-title",
                                        {
                                          staticStyle: { "font-size": "14px" },
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("driver_info")) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { col: "12" },
                                            },
                                            [
                                              _c(
                                                "v-simple-table",
                                                { attrs: { dense: "" } },
                                                [
                                                  _c("tbody", [
                                                    _c("tr", [
                                                      _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            width: "140px",
                                                          },
                                                        },
                                                        [
                                                          _c("strong", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t("name")
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "lastname"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                      _c("td", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm
                                                                .reservationData
                                                                .driverInfo.name
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              _vm
                                                                .reservationData
                                                                .driverInfo
                                                                .lastname
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                      _c("td"),
                                                    ]),
                                                    _c("tr", [
                                                      _c("td", [
                                                        _c("strong", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t("phone")
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.phoneFormat
                                                          )
                                                        ),
                                                      ]),
                                                      _c(
                                                        "td",
                                                        [
                                                          _vm.$store.state
                                                            .GetServerData
                                                            .departmanRole ===
                                                            "CustomerExperience" &&
                                                          _vm.$store.state
                                                            .GetServerData
                                                            .role ==
                                                            "SUPERUSER" &&
                                                          _vm.$store.state
                                                            .GetServerData
                                                            .userType == "admin"
                                                            ? _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "green lighten-1",
                                                                    small: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        _vm.driverInfoPhone = true
                                                                      },
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.driverInfoPhone,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.driverInfoPhone =
                                                                          $$v
                                                                      },
                                                                    expression:
                                                                      "driverInfoPhone",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Güncelle"
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ]),
                                                    _c("tr", [
                                                      _c("td", [
                                                        _c("strong", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t("email")
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.reservationData
                                                              .driverInfo.email
                                                          )
                                                        ),
                                                      ]),
                                                      _c(
                                                        "td",
                                                        [
                                                          _vm.$store.state
                                                            .GetServerData
                                                            .departmanRole ===
                                                            "CustomerExperience" &&
                                                          _vm.$store.state
                                                            .GetServerData
                                                            .role ==
                                                            "SUPERUSER" &&
                                                          _vm.$store.state
                                                            .GetServerData
                                                            .userType == "admin"
                                                            ? _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "green lighten-1",
                                                                    small: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        _vm.driverInfoEmail = true
                                                                      },
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.driverInfoEmail,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.driverInfoEmail =
                                                                          $$v
                                                                      },
                                                                    expression:
                                                                      "driverInfoEmail",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Güncelle"
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ]),
                                                    _c("tr", [
                                                      _c("td", [
                                                        _c("strong", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "identity"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$store.state
                                                                .ceviri[
                                                                _vm
                                                                  .reservationData
                                                                  .driverInfo
                                                                  .identity
                                                                  .certificateType
                                                              ]
                                                            ) +
                                                            " / " +
                                                            _vm._s(
                                                              _vm
                                                                .reservationData
                                                                .driverInfo
                                                                .identity.value
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                      _c("td"),
                                                    ]),
                                                    _c("tr", [
                                                      _c("td", [
                                                        _c("strong", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "date_of_birth"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.birthdayMoment
                                                            ) +
                                                            " (" +
                                                            _vm._s(
                                                              _vm.birthdayMoment &&
                                                                _vm.yearMoment
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "years_old"
                                                              )
                                                            ) +
                                                            " ) "
                                                        ),
                                                      ]),
                                                      _c("td"),
                                                    ]),
                                                  ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "CModal",
                                {
                                  attrs: {
                                    title: "Telefon",
                                    show: _vm.driverInfoPhone,
                                  },
                                  on: {
                                    "update:show": function ($event) {
                                      _vm.driverInfoPhone = $event
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "footer",
                                        fn: function () {
                                          return [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "primary",
                                                attrs: { small: "" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.driverInfoUpdate(
                                                      "phone"
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("Güncelle")]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    4165093339
                                  ),
                                },
                                [
                                  _c(
                                    "CRow",
                                    [
                                      _c(
                                        "CCol",
                                        { attrs: { sm: "12" } },
                                        [
                                          _c("CInput", {
                                            attrs: { label: "Telefon" },
                                            model: {
                                              value: _vm.driverInfoPhoneForm,
                                              callback: function ($$v) {
                                                _vm.driverInfoPhoneForm = $$v
                                              },
                                              expression: "driverInfoPhoneForm",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "CModal",
                                {
                                  attrs: {
                                    title: "Email",
                                    show: _vm.driverInfoEmail,
                                  },
                                  on: {
                                    "update:show": function ($event) {
                                      _vm.driverInfoEmail = $event
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "footer",
                                        fn: function () {
                                          return [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "primary",
                                                attrs: { small: "" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.driverInfoUpdate(
                                                      "email"
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("Güncelle")]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    599603787
                                  ),
                                },
                                [
                                  _c(
                                    "CRow",
                                    [
                                      _c(
                                        "CCol",
                                        { attrs: { sm: "12" } },
                                        [
                                          _c("CInput", {
                                            attrs: { label: "Email" },
                                            model: {
                                              value: _vm.driverInfoEmailForm,
                                              callback: function ($$v) {
                                                _vm.driverInfoEmailForm = $$v
                                              },
                                              expression: "driverInfoEmailForm",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-card",
                                {
                                  staticClass: "mt-4",
                                  attrs: { elevation: "10" },
                                },
                                [
                                  _vm.invoicesStatus
                                    ? _c(
                                        "v-toolbar",
                                        {
                                          attrs: {
                                            height: "40",
                                            dense: "",
                                            dark: "",
                                            color: "grey darken-1",
                                            elevation: "0",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-toolbar-title",
                                            {
                                              staticStyle: {
                                                "font-size": "14px",
                                              },
                                            },
                                            [
                                              _c("strong", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "billing_information"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.invoicesStatus
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "pa-0",
                                                  attrs: { col: "12" },
                                                },
                                                [
                                                  _c(
                                                    "v-simple-table",
                                                    { attrs: { dense: "" } },
                                                    [
                                                      _c("tbody", [
                                                        _c("tr", [
                                                          _c(
                                                            "td",
                                                            {
                                                              staticStyle: {
                                                                width: "140px",
                                                              },
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "type"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm
                                                                  .invoiceDetail
                                                                  .invoiceType
                                                              )
                                                            ),
                                                          ]),
                                                        ]),
                                                        _vm.invoiceDetail
                                                          .title &&
                                                        _vm.invoiceDetail
                                                          .title !==
                                                          "undefined undefined"
                                                          ? _c("tr", [
                                                              _c("td", [
                                                                _c("strong", [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "company_title"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]),
                                                              ]),
                                                              _c("td", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .invoiceDetail
                                                                        .title
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                            ])
                                                          : _vm._e(),
                                                        _vm.partnerBrand
                                                          ? _c("tr", [
                                                              _c("td", [
                                                                _c("strong", [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "brand"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]),
                                                              ]),
                                                              _c("td", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.partnerBrand
                                                                  )
                                                                ),
                                                              ]),
                                                            ])
                                                          : _vm._e(),
                                                        _c("tr", [
                                                          _c("td", [
                                                            _c("strong", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "address"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]),
                                                          ]),
                                                          _c("td", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm
                                                                    .invoiceDetail
                                                                    .address
                                                                ) +
                                                                " "
                                                            ),
                                                          ]),
                                                        ]),
                                                        _vm.invoiceDetail
                                                          .taxOffice
                                                          ? _c("tr", [
                                                              _c("td", [
                                                                _c("strong", [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "tax_number"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]),
                                                              ]),
                                                              _c("td", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .invoiceDetail
                                                                        .taxOffice
                                                                    ) +
                                                                    " / " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .invoiceDetail
                                                                        .taxNumber
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                            ])
                                                          : _vm._e(),
                                                        _vm.invoiceDetail
                                                          .invoiceNumber
                                                          ? _c("tr", [
                                                              _c("td", [
                                                                _c("strong", [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "status"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]),
                                                              ]),
                                                              _c(
                                                                "td",
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm
                                                                          .invoiceDetail
                                                                          .invoiceTransferStatus ==
                                                                          "ok"
                                                                          ? _vm.$t(
                                                                              "invoiced"
                                                                            )
                                                                          : _vm
                                                                              .invoiceDetail
                                                                              .invoiceTransferStatus ==
                                                                            "pending"
                                                                          ? _vm.$t(
                                                                              "pending"
                                                                            )
                                                                          : _vm
                                                                              .invoiceDetail
                                                                              .invoiceTransferStatus ==
                                                                            "error"
                                                                          ? _vm.$t(
                                                                              "error_t"
                                                                            )
                                                                          : ""
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                  _vm
                                                                    .invoiceDetail
                                                                    .invoiceERPMessage
                                                                    ? _c(
                                                                        "font-awesome-icon",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "c-tooltip",
                                                                                rawName:
                                                                                  "v-c-tooltip",
                                                                                value:
                                                                                  {
                                                                                    content:
                                                                                      _vm
                                                                                        .invoiceDetail
                                                                                        .invoiceERPMessage,
                                                                                    placement:
                                                                                      "top",
                                                                                  },
                                                                                expression:
                                                                                  "{\n                                content: invoiceDetail.invoiceERPMessage,\n                                placement: 'top',\n                              }",
                                                                              },
                                                                            ],
                                                                          staticClass:
                                                                            "mr-2",
                                                                          attrs:
                                                                            {
                                                                              icon: [
                                                                                "fas",
                                                                                "info-circle",
                                                                              ],
                                                                            },
                                                                        }
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                        _vm.invoiceDetail
                                                          .invoiceNumber
                                                          ? _c("tr", [
                                                              _c("td", [
                                                                _c("strong", [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "invoice_number"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]),
                                                              ]),
                                                              _c("td", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .invoiceDetail
                                                                        .invoiceNumber
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                            ])
                                                          : _vm._e(),
                                                        _vm.invoiceDetail
                                                          .invoiceDate
                                                          ? _c("tr", [
                                                              _c("td", [
                                                                _c("strong", [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "invoice_date"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]),
                                                              ]),
                                                              _c("td", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .moment(
                                                                          _vm
                                                                            .invoiceDetail
                                                                            .invoiceDate
                                                                        )
                                                                        .format(
                                                                          "DD-MM-YYYY"
                                                                        )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                            ])
                                                          : _vm._e(),
                                                      ]),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm.reservationData.status != "pending" &&
                              _vm.reservationData.status != "paymentError" &&
                              _vm.reservationData.paymentType != "debit"
                                ? _c(
                                    "v-card",
                                    {
                                      staticClass: "mt-4",
                                      attrs: { elevation: "10" },
                                    },
                                    [
                                      _c(
                                        "v-toolbar",
                                        {
                                          attrs: {
                                            height: "40",
                                            dense: "",
                                            dark: "",
                                            color: "grey darken-1",
                                            elevation: "0",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-toolbar-title",
                                            {
                                              staticStyle: {
                                                "font-size": "14px",
                                              },
                                            },
                                            [
                                              _c("strong", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("payment_info")
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.invoicesStatus
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "pa-0",
                                                      attrs: { col: "12" },
                                                    },
                                                    [
                                                      _c(
                                                        "v-simple-table",
                                                        {
                                                          attrs: { dense: "" },
                                                        },
                                                        [
                                                          _c("tbody", [
                                                            _c("tr", [
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticStyle: {
                                                                    width:
                                                                      "140px",
                                                                  },
                                                                },
                                                                [
                                                                  _c("strong", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "amount_paid"
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                              _c("td", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .bankTransactions
                                                                        .TotalAmount
                                                                        ? _vm.func.toMoneyFormat(
                                                                            _vm
                                                                              .bankTransactions
                                                                              .TotalAmount
                                                                          ) +
                                                                            " ₺"
                                                                        : "0 ₺"
                                                                    ) +
                                                                    " "
                                                                ),
                                                                _c("small", [
                                                                  _vm._v(
                                                                    "( " +
                                                                      _vm._s(
                                                                        _vm
                                                                          .bankTransactions
                                                                          .InstallmentCount ==
                                                                          1 ||
                                                                          !_vm
                                                                            .bankTransactions
                                                                            .InstallmentCount
                                                                          ? _vm.$t(
                                                                              "advance"
                                                                            )
                                                                          : _vm.$t(
                                                                              "installment"
                                                                            ) +
                                                                              " /" +
                                                                              _vm
                                                                                .bankTransactions
                                                                                .InstallmentCount
                                                                      ) +
                                                                      " )"
                                                                  ),
                                                                ]),
                                                              ]),
                                                            ]),
                                                            _c("tr", [
                                                              _c("td", [
                                                                _c("strong", [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "order_number"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]),
                                                              ]),
                                                              _c("td", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.reservationData &&
                                                                        _vm
                                                                          .reservationData
                                                                          .orderId
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                            ]),
                                                            _vm.bankTransactions
                                                              .VposName
                                                              ? _c("tr", [
                                                                  _c("td", [
                                                                    _c(
                                                                      "strong",
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "bank"
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                  _c("td", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm
                                                                            .bankTransactions
                                                                            .VposName
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]),
                                                                ])
                                                              : _vm._e(),
                                                            _vm.bankTransactions
                                                              .CardNumber
                                                              ? _c("tr", [
                                                                  _c("td", [
                                                                    _c(
                                                                      "strong",
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "card_number"
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                  _c("td", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm
                                                                            .bankTransactions
                                                                            .CardNumber
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]),
                                                                ])
                                                              : _vm._e(),
                                                            _vm.reservationData
                                                              ?.payments?.length
                                                              ? _c("tr", [
                                                                  _c("td", [
                                                                    _c(
                                                                      "strong",
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "card_type"
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                  _c("td", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm
                                                                            .reservationData
                                                                            .payments[0]
                                                                            .creditCardCountry ==
                                                                            "nativeCard"
                                                                            ? _vm.$t(
                                                                                "local"
                                                                              )
                                                                            : _vm
                                                                                .reservationData
                                                                                ?.payments[0]
                                                                                ?.creditCardCountry ==
                                                                              "otherCard"
                                                                            ? _vm.$t(
                                                                                "abroad"
                                                                              )
                                                                            : ""
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]),
                                                                ])
                                                              : _vm._e(),
                                                            _vm.reservationData
                                                              ?.payments?.length
                                                              ? _c("tr", [
                                                                  _c("td", [
                                                                    _c(
                                                                      "strong",
                                                                      [
                                                                        _vm._v(
                                                                          "POS"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                  _c("td", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm
                                                                            .reservationData
                                                                            ?.payments[0]
                                                                            ?.posModule
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]),
                                                                ])
                                                              : _vm._e(),
                                                          ]),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.reservationData.description
                                ? _c(
                                    "v-card",
                                    {
                                      staticClass: "mt-4",
                                      attrs: { elevation: "10" },
                                    },
                                    [
                                      _c(
                                        "v-toolbar",
                                        {
                                          attrs: {
                                            height: "40",
                                            dense: "",
                                            dark: "",
                                            color: "grey darken-1",
                                            elevation: "0",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-toolbar-title",
                                            {
                                              staticStyle: {
                                                "font-size": "14px",
                                              },
                                            },
                                            [
                                              _c("strong", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "description_to_supplier"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("div", { staticClass: "pa-3" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.reservationData.description
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12",
                                lg: "5",
                                md: "12",
                                sm: "12",
                              },
                            },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "mb-5",
                                  attrs: { elevation: "10" },
                                },
                                [
                                  _c(
                                    "v-toolbar",
                                    {
                                      attrs: {
                                        height: "40",
                                        dense: "",
                                        dark: "",
                                        color: "grey darken-1",
                                        elevation: "0",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-toolbar-title",
                                        {
                                          staticStyle: { "font-size": "14px" },
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v(_vm._s(_vm.$t("general"))),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { col: "12" },
                                            },
                                            [
                                              _c(
                                                "v-simple-table",
                                                { attrs: { dense: "" } },
                                                [
                                                  _c("tbody", [
                                                    _c("tr", [
                                                      _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            width: "150px",
                                                          },
                                                        },
                                                        [
                                                          _c("strong", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "status"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                      _c("td", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$store.state
                                                                .ceviri[
                                                                _vm
                                                                  .reservationData
                                                                  .status
                                                              ]
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                    ]),
                                                    _c("tr", [
                                                      _c("td", [
                                                        _c("strong", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "reservation_number"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.reservationData
                                                              .reservationCode
                                                          )
                                                        ),
                                                      ]),
                                                    ]),
                                                    _c("tr", [
                                                      _c("td", [
                                                        _c("strong", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "vendor_reservation_number"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.reservationData
                                                              .vendorReservationCode
                                                          )
                                                        ),
                                                      ]),
                                                    ]),
                                                    _c("tr", [
                                                      _c("td", [
                                                        _c("strong", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t("day")
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.reservationData
                                                              .searchPrice
                                                              .totalDays
                                                          )
                                                        ),
                                                      ]),
                                                    ]),
                                                    _vm.reservationData
                                                      .status ==
                                                    `confirmedEarlyReturn`
                                                      ? _c("tr", [
                                                          _c("td", [
                                                            _c(
                                                              "strong",
                                                              {
                                                                staticClass:
                                                                  "text-decoration-underline",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      "İade Gün"
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]),
                                                          _c(
                                                            "td",
                                                            {
                                                              staticClass:
                                                                "text-decoration-underline",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .EarlyReturnCardInfo
                                                                      .DiffTime
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                    _vm.reservationData
                                                      .searchPrice.createdAt
                                                      ? _c("tr", [
                                                          _c("td", [
                                                            _c("strong", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "search_date"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]),
                                                          ]),
                                                          _c(
                                                            "td",
                                                            {
                                                              staticStyle: {
                                                                "font-size":
                                                                  "11px",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .moment(
                                                                        _vm
                                                                          .reservationData
                                                                          .searchPrice
                                                                          .createdAt
                                                                      )
                                                                      .local()
                                                                      .format(
                                                                        "DD-MM-YYYY HH:mm"
                                                                      )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                    _c("tr", [
                                                      _c("td", [
                                                        _c("strong", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "process_date"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                      ]),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            "font-size": "11px",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm
                                                                  .moment(
                                                                    _vm
                                                                      .reservationData
                                                                      .reservationDateTime
                                                                  )
                                                                  .local()
                                                                  .format(
                                                                    "DD-MM-YYYY HH:mm"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]),
                                                    _c("tr", [
                                                      _c("td", [
                                                        _c("strong", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t("trader")
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm
                                                                .reservationData
                                                                ?.modifications[0]
                                                                .userType ==
                                                                "agent" ||
                                                                _vm
                                                                  .reservationData
                                                                  ?.modifications[0]
                                                                  .userType ==
                                                                  "admin"
                                                                ? _vm.$store
                                                                    .state
                                                                    .ceviri[
                                                                    _vm
                                                                      .reservationData
                                                                      ?.modifications[0]
                                                                      ?.departmanRole
                                                                  ]
                                                                : _vm.$store
                                                                    .state
                                                                    .ceviri[
                                                                    _vm
                                                                      .reservationData
                                                                      ?.modifications[0]
                                                                      ?.userType
                                                                  ]
                                                            ) +
                                                            " "
                                                        ),
                                                        _vm.reservationData
                                                          .partnerDetail
                                                          ? _c(
                                                              "small",
                                                              {
                                                                staticClass:
                                                                  "font-weight-bold d-block",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm
                                                                      .reservationData
                                                                      ?.partnerDetail
                                                                      ?.title
                                                                  ) + " "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _c(
                                                          "small",
                                                          {
                                                            staticClass:
                                                              "font-weight-bold d-block",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm
                                                                  .reservationData
                                                                  ?.modifications[0]
                                                                  ?.fullname
                                                              ) + " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                    ]),
                                                    _vm.reservationData
                                                      .paymentType
                                                      ? _c("tr", [
                                                          _c("td", [
                                                            _c("strong", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "payment_type"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]),
                                                          ]),
                                                          _c("td", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$store
                                                                    .state
                                                                    .ceviri[
                                                                    _vm
                                                                      .reservationData
                                                                      .paymentType
                                                                  ]
                                                                ) +
                                                                " "
                                                            ),
                                                          ]),
                                                        ])
                                                      : _vm._e(),
                                                    _vm.reservationData
                                                      .reservationType
                                                      ? _c("tr", [
                                                          _c("td", [
                                                            _c("strong", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "reservation_type"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]),
                                                          ]),
                                                          _c("td", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    _vm
                                                                      .reservationData
                                                                      .reservationType
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]),
                                                        ])
                                                      : _vm._e(),
                                                    _vm.reservationData
                                                      ?.currency?.forexSelling >
                                                      1 && !_vm.isPartner
                                                      ? _c("tr", [
                                                          _c("td", [
                                                            _c("strong", [
                                                              _vm._v(" Kur "),
                                                            ]),
                                                          ]),
                                                          _c("td", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm
                                                                    .reservationData
                                                                    ?.currency
                                                                    ?.forexSelling
                                                                ) +
                                                                " "
                                                            ),
                                                          ]),
                                                        ])
                                                      : _vm._e(),
                                                    _vm.reservationData
                                                      ?.utmSource?.length
                                                      ? _c("tr", [
                                                          _c("td", [
                                                            _c("strong", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "Kaynak"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]),
                                                          ]),
                                                          _c("td", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm
                                                                    .reservationData
                                                                    .utmSource
                                                                ) +
                                                                " "
                                                            ),
                                                          ]),
                                                        ])
                                                      : _vm._e(),
                                                    _vm.reservationData
                                                      ?.metropolCard?.length &&
                                                    _vm.isPay
                                                      ? _c("tr", [
                                                          _c("td", [
                                                            _c("strong", [
                                                              _vm._v(
                                                                " Metropol Kart "
                                                              ),
                                                            ]),
                                                          ]),
                                                          _c("td", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm
                                                                    .reservationData
                                                                    ?.metropolCard
                                                                ) +
                                                                " "
                                                            ),
                                                          ]),
                                                        ])
                                                      : _vm._e(),
                                                    _vm.reservationData
                                                      .driverInfo.flightNo &&
                                                    _vm.reservationData
                                                      .driverInfo.flightNo != ""
                                                      ? _c("tr", [
                                                          _c("td", [
                                                            _c("strong", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "flight_number"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]),
                                                          ]),
                                                          _c("td", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm
                                                                    .reservationData
                                                                    .driverInfo
                                                                    .flightNo
                                                                ) +
                                                                " "
                                                            ),
                                                          ]),
                                                        ])
                                                      : _vm._e(),
                                                  ]),
                                                ]
                                              ),
                                              _vm.reservationData.status !=
                                                "paymentError" &&
                                              _vm.reservationData.status !=
                                                "pending"
                                                ? _c(
                                                    "v-col",
                                                    [
                                                      _vm.reservationData
                                                        .status != "pending" &&
                                                      _vm.reservationData
                                                        .status !=
                                                        "cancelled" &&
                                                      _vm.reservationData
                                                        .status != "completed"
                                                        ? _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "mb-1 down-btn",
                                                              staticStyle: {
                                                                "white-space":
                                                                  "normal",
                                                                "word-wrap":
                                                                  "break-word",
                                                              },
                                                              attrs: {
                                                                block: "",
                                                                outlined: "",
                                                                small: "",
                                                                color:
                                                                  "primary font-weight-bold",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.reservationEmailSend(
                                                                      _vm
                                                                        .reservationData
                                                                        ._id
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "send_reservation_email"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm.reservationData
                                                        .status == "pending" ||
                                                      _vm.reservationData
                                                        .status ==
                                                        "paymentError"
                                                        ? _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "mb-1 down-btn",
                                                              staticStyle: {
                                                                "white-space":
                                                                  "normal",
                                                                "word-wrap":
                                                                  "break-word",
                                                              },
                                                              attrs: {
                                                                block: "",
                                                                small: "",
                                                                color:
                                                                  "primary font-weight-bold",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.beginIVR(
                                                                      _vm
                                                                        .reservationData
                                                                        .reservationCode
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "forward_call_ivr"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm.reservationData
                                                        .status != "pending"
                                                        ? _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "mb-1 down-btn",
                                                              staticStyle: {
                                                                "white-space":
                                                                  "normal",
                                                                "word-wrap":
                                                                  "break-word",
                                                              },
                                                              attrs: {
                                                                block: "",
                                                                outlined: "",
                                                                small: "",
                                                                color:
                                                                  "primary  font-weight-bold",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.reservationPdfDownload(
                                                                      _vm
                                                                        .reservationData
                                                                        .driverInfo
                                                                        .email,
                                                                      _vm
                                                                        .reservationData
                                                                        .reservationCode
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "download_reservation_document"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c("RoleProvider", {
                                                        attrs: {
                                                          slug: "RESERVATION_EARLY_RETURN_BEGIN",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function ({
                                                                canItPass,
                                                              }) {
                                                                return _c(
                                                                  "div",
                                                                  {},
                                                                  [
                                                                    _vm.earlyReturnRequestButton() &&
                                                                    canItPass
                                                                      ? _c(
                                                                          "v-btn",
                                                                          {
                                                                            staticClass:
                                                                              "mb-1",
                                                                            attrs:
                                                                              {
                                                                                block:
                                                                                  "",
                                                                                small:
                                                                                  "",
                                                                                color:
                                                                                  "warning text-white font-weight-bold",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  _vm.earlyReturnModal = true
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "early_return_request"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  1
                                                                )
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          1793004543
                                                        ),
                                                      }),
                                                      _c("RoleProvider", {
                                                        attrs: {
                                                          slug: "RESERVATION_EARLY_RETURN_BEGIN",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function ({
                                                                canItPass,
                                                              }) {
                                                                return _c(
                                                                  "div",
                                                                  {},
                                                                  [
                                                                    _vm
                                                                      .reservationData
                                                                      .status ==
                                                                      "earlyReturnRequest" ||
                                                                    (_vm
                                                                      .reservationData
                                                                      .status ==
                                                                      "earlyReturnRejected" &&
                                                                      canItPass)
                                                                      ? _c(
                                                                          "v-btn",
                                                                          {
                                                                            staticClass:
                                                                              "mb-1",
                                                                            attrs:
                                                                              {
                                                                                block:
                                                                                  "",
                                                                                small:
                                                                                  "",
                                                                                color:
                                                                                  "warning text-white font-weight-bold",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  _vm.formEarlyReturnRegistrationModal = true
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "early_return_registration"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  1
                                                                )
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          3673298921
                                                        ),
                                                      }),
                                                      _c("RoleProvider", {
                                                        attrs: {
                                                          slug: "RESERVATION_EARLY_RETURN_BEGIN",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function ({
                                                                canItPass,
                                                              }) {
                                                                return _c(
                                                                  "div",
                                                                  {},
                                                                  [
                                                                    _vm
                                                                      .reservationData
                                                                      .status ==
                                                                      "earlyReturnFinancialConfirmation" &&
                                                                    canItPass
                                                                      ? _c(
                                                                          "v-btn",
                                                                          {
                                                                            staticClass:
                                                                              "mb-1",
                                                                            attrs:
                                                                              {
                                                                                small:
                                                                                  "",
                                                                                block:
                                                                                  "",
                                                                                color:
                                                                                  "error font-weight-bold",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.rejectEarlyReturn()
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "early_return_rejection"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  1
                                                                )
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          4263067642
                                                        ),
                                                      }),
                                                      _c("RoleProvider", {
                                                        attrs: {
                                                          slug: "RESERVATION_REFUND_PAYMENT",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function ({
                                                                canItPass,
                                                              }) {
                                                                return _c(
                                                                  "div",
                                                                  {},
                                                                  [
                                                                    _vm
                                                                      .reservationData
                                                                      .paymentType ==
                                                                      `debit` &&
                                                                    (_vm
                                                                      .reservationData
                                                                      .status ==
                                                                      `active` ||
                                                                      _vm
                                                                        .reservationData
                                                                        .status ==
                                                                        `completed`)
                                                                      ? _c(
                                                                          "v-btn",
                                                                          {
                                                                            staticClass:
                                                                              "mb-1",
                                                                            attrs:
                                                                              {
                                                                                block:
                                                                                  "",
                                                                                small:
                                                                                  "",
                                                                                color:
                                                                                  "secondary text-white font-weight-bold",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  _vm.refundPaymentModel = true
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "refund_payment"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  1
                                                                )
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          1224466192
                                                        ),
                                                      }),
                                                      _c("RoleProvider", {
                                                        attrs: {
                                                          slug: "RESERVATION_REFUND_PAYMENT",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function ({
                                                                canItPass,
                                                              }) {
                                                                return _c(
                                                                  "div",
                                                                  {},
                                                                  [
                                                                    _c(
                                                                      "v-btn",
                                                                      {
                                                                        staticClass:
                                                                          "mb-1",
                                                                        attrs: {
                                                                          block:
                                                                            "",
                                                                          small:
                                                                            "",
                                                                          color:
                                                                            "secondary text-white font-weight-bold",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              _vm.noShowRefundPaymentModel = true
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "noshow_refund_payment"
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          1039614826
                                                        ),
                                                      }),
                                                      _c("RoleProvider", {
                                                        attrs: {
                                                          slug: "RESERVATION_EARLY_RETURN_SUCCESS_REJECT",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function ({
                                                                canItPass,
                                                              }) {
                                                                return _c(
                                                                  "div",
                                                                  {},
                                                                  [
                                                                    _vm
                                                                      .reservationData
                                                                      .status ==
                                                                      "earlyReturnFinancialConfirmation" &&
                                                                    canItPass
                                                                      ? _c(
                                                                          "v-btn",
                                                                          {
                                                                            staticClass:
                                                                              "mb-1",
                                                                            attrs:
                                                                              {
                                                                                small:
                                                                                  "",
                                                                                block:
                                                                                  "",
                                                                                color:
                                                                                  "success text-white font-weight-bold",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.confirmEarlyReturn()
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "early_return_confirmation"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  1
                                                                )
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          935272099
                                                        ),
                                                      }),
                                                      _c("RoleProvider", {
                                                        attrs: {
                                                          slug: "RESERVATION_COMMENT_CREATE",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function ({
                                                                canItPass,
                                                              }) {
                                                                return _c(
                                                                  "div",
                                                                  {},
                                                                  [
                                                                    _vm
                                                                      .reservationData
                                                                      .status ==
                                                                      "completed" &&
                                                                    canItPass
                                                                      ? _c(
                                                                          "v-btn",
                                                                          {
                                                                            staticClass:
                                                                              "mb-1",
                                                                            attrs:
                                                                              {
                                                                                block:
                                                                                  "",
                                                                                small:
                                                                                  "",
                                                                                color:
                                                                                  "warning text-white font-weight-bold",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.surveySend(
                                                                                    _vm
                                                                                      .reservationData
                                                                                      .reservationCode
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "send_survey"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  1
                                                                )
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          2939767187
                                                        ),
                                                      }),
                                                      _c("RoleProvider", {
                                                        attrs: {
                                                          slug: "RESERVATION_DELETE",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function ({
                                                                canItPass,
                                                              }) {
                                                                return _c(
                                                                  "div",
                                                                  {},
                                                                  [
                                                                    _vm
                                                                      .reservationData
                                                                      .status !==
                                                                      "cancelled" &&
                                                                    _vm
                                                                      .reservationData
                                                                      .status !==
                                                                      "earlyReturnRequest" &&
                                                                    _vm
                                                                      .reservationData
                                                                      .status !==
                                                                      "earlyReturnFinancialConfirmation" &&
                                                                    _vm
                                                                      .reservationData
                                                                      .status !==
                                                                      "pending" &&
                                                                    _vm
                                                                      .reservationData
                                                                      .consensus
                                                                      .status !=
                                                                      "completed" &&
                                                                    canItPass
                                                                      ? _c(
                                                                          "v-btn",
                                                                          {
                                                                            staticClass:
                                                                              "mb-1",
                                                                            attrs:
                                                                              {
                                                                                small:
                                                                                  "",
                                                                                block:
                                                                                  "",
                                                                                color:
                                                                                  "error font-weight-bold",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.cacelBtnClick(
                                                                                    "all"
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "cancel_btn"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  1
                                                                )
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          2744785296
                                                        ),
                                                      }),
                                                      _c("RoleProvider", {
                                                        attrs: {
                                                          slug: "RESERVATION_DELETE",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function ({
                                                                canItPass,
                                                              }) {
                                                                return _c(
                                                                  "div",
                                                                  {},
                                                                  [
                                                                    canItPass &&
                                                                    _vm
                                                                      .reservationData
                                                                      .status !=
                                                                      "cancelled"
                                                                      ? _c(
                                                                          "v-btn",
                                                                          {
                                                                            staticClass:
                                                                              "mb-1",
                                                                            attrs:
                                                                              {
                                                                                small:
                                                                                  "",
                                                                                block:
                                                                                  "",
                                                                                color:
                                                                                  "light",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  _vm.reservationSetting.modalShow = true
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "change"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  1
                                                                )
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          1888928414
                                                        ),
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "v-col",
                                                [
                                                  _vm.reservationData
                                                    .paymentType ==
                                                    "noneSecurePos" &&
                                                  !_vm.isPay
                                                    ? _c(
                                                        "v-btn",
                                                        {
                                                          staticClass: "mb-1",
                                                          attrs: {
                                                            block: "",
                                                            small: "",
                                                            color:
                                                              "primary font-weight-bold",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.paymentModalShow =
                                                                !_vm.paymentModalShow
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "pay_with_virtual_pos"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { md: "12", sm: "12", cols: "12" } },
                    [
                      _vm.cancelRezCarInfo?.CardShow
                        ? _c(
                            "v-card",
                            { staticClass: "mb-5", attrs: { elevation: "10" } },
                            [
                              _c(
                                "v-toolbar",
                                {
                                  staticClass: "grey darken-1",
                                  attrs: {
                                    height: "40",
                                    dense: "",
                                    dark: "",
                                    elevation: "0",
                                  },
                                },
                                [
                                  _c(
                                    "v-toolbar-title",
                                    { staticStyle: { "font-size": "14px" } },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.cancelRezCarInfo.title) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-card-text",
                                { staticClass: "px-4 pt-2 pb-3" },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "6",
                                            md: "4",
                                          },
                                        },
                                        [
                                          _c("strong", [_vm._v("İŞLEM TİPİ:")]),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.cancelRezCarInfo.type
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "6",
                                            md: "4",
                                          },
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v("İADE EDİLEN TUTAR:"),
                                          ]),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.cancelRezCarInfo.refundPrice
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "6",
                                            md: "4",
                                          },
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v("İPTAL EDEN KULLANICI:"),
                                          ]),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.cancelRezCarInfo.personİnfo
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _vm.cancelRezCarInfo.place !== `Agent`
                                        ? _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "12",
                                                sm: "6",
                                                md: "4",
                                              },
                                            },
                                            [
                                              _c("strong", [
                                                _vm._v("İPTAL EDEN :"),
                                              ]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.cancelRezCarInfo.place
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "12",
                                                sm: "6",
                                                md: "4",
                                              },
                                            },
                                            [
                                              _c("strong", [
                                                _vm._v(
                                                  "İPTAL EDEN DEPARTMANI:"
                                                ),
                                              ]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.cancelRezCarInfo
                                                      .departmanRole
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "6",
                                            md: "4",
                                          },
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v("İPTAL TARİHİ:"),
                                          ]),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm
                                                  .moment(
                                                    _vm.cancelRezCarInfo.date
                                                  )
                                                  .local()
                                                  .format("DD-MM-YYYY HH:mm")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "6",
                                            md: "4",
                                          },
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v("İPTAL MESAJI:"),
                                          ]),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.cancelRezCarInfo.message
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.noShowRefundCardInfo.CardShow
                        ? _c(
                            "v-card",
                            { staticClass: "mb-5", attrs: { elevation: "10" } },
                            [
                              _c(
                                "v-toolbar",
                                {
                                  staticClass: "grey darken-1",
                                  attrs: {
                                    height: "40",
                                    dense: "",
                                    dark: "",
                                    elevation: "0",
                                  },
                                },
                                [
                                  _c(
                                    "v-toolbar-title",
                                    { staticStyle: { "font-size": "14px" } },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.noShowRefundCardInfo.title
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-card-text",
                                { staticClass: "px-4 pt-2 pb-3" },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "6",
                                            md: "4",
                                          },
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v("İptal Tarihi :"),
                                          ]),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm
                                                  .moment(
                                                    _vm.noShowRefundCardInfo
                                                      .date
                                                  )
                                                  .local()
                                                  .format("DD-MM-YYYY HH:mm")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _vm.noShowRefundCardInfo.paymentType !==
                                      `debit`
                                        ? _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "12",
                                                sm: "6",
                                                md: "4",
                                              },
                                            },
                                            [
                                              _c("strong", [
                                                _vm._v("İade Edilen Tutar:"),
                                              ]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.noShowRefundCardInfo
                                                      .refundPrice
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.noShowRefundCardInfo.paymentType ==
                                      `debit`
                                        ? _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "12",
                                                sm: "6",
                                                md: "4",
                                              },
                                            },
                                            [
                                              _c("strong", [
                                                _vm._v(
                                                  "İadesi Talep Edilen Tutar:"
                                                ),
                                              ]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.noShowRefundCardInfo
                                                      .reqPrice
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "6",
                                            md: "4",
                                          },
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v("İşlemi Gerçekleştiren:"),
                                          ]),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.noShowRefundCardInfo
                                                  .personİnfo
                                              ) +
                                              _vm._s("/") +
                                              " " +
                                              _vm._s(
                                                _vm.noShowRefundCardInfo
                                                  .departmanRole
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.EarlyReturnCardInfo.CardShow
                        ? _c(
                            "v-card",
                            { staticClass: "mb-5", attrs: { elevation: "10" } },
                            [
                              _c(
                                "v-toolbar",
                                {
                                  staticClass: "grey darken-1",
                                  attrs: {
                                    height: "40",
                                    dense: "",
                                    dark: "",
                                    elevation: "0",
                                  },
                                },
                                [
                                  _c(
                                    "v-toolbar-title",
                                    { staticStyle: { "font-size": "14px" } },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.EarlyReturnCardInfo.title
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-card-text",
                                { staticClass: "px-4 pt-2 pb-3" },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "6",
                                            md: "4",
                                          },
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v("İade Tarihi :"),
                                          ]),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm
                                                  .moment(
                                                    _vm.EarlyReturnCardInfo
                                                      .earlyReturnDate
                                                  )
                                                  .local()
                                                  .format("D MMMM YYYY")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "6",
                                            md: "4",
                                          },
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v("İade Ücreti:"),
                                          ]),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.EarlyReturnCardInfo
                                                  .earlyReturnPayable
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "6",
                                            md: "4",
                                          },
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v("İade Mesajı:"),
                                          ]),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.EarlyReturnCardInfo.message
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "6",
                                            md: "4",
                                          },
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v("İşlemi Gerçekleştiren:"),
                                          ]),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.EarlyReturnCardInfo
                                                  .personİnfo
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "6",
                                            md: "4",
                                          },
                                        },
                                        [
                                          _c("strong", [_vm._v("Departman:")]),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.EarlyReturnCardInfo
                                                  .departmanRole
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "6",
                                            md: "4",
                                          },
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v("İşlem Tarihi:"),
                                          ]),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm
                                                  .moment(
                                                    _vm.EarlyReturnCardInfo.date
                                                  )
                                                  .local()
                                                  .format("D MMMM YYYY")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.refundPayCardInfo.CardShow
                        ? _c(
                            "v-card",
                            { staticClass: "mb-5", attrs: { elevation: "10" } },
                            [
                              _c(
                                "v-toolbar",
                                {
                                  staticClass: "grey darken-1",
                                  attrs: {
                                    height: "40",
                                    dense: "",
                                    dark: "",
                                    elevation: "0",
                                  },
                                },
                                [
                                  _c(
                                    "v-toolbar-title",
                                    { staticStyle: { "font-size": "14px" } },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.refundPayCardInfo.title
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-card-text",
                                { staticClass: "px-4 pt-2 pb-3" },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "6",
                                            md: "4",
                                          },
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v("İptal Tarihi :"),
                                          ]),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm
                                                  .moment(
                                                    _vm.refundPayCardInfo.date
                                                  )
                                                  .local()
                                                  .format("DD-MM-YYYY HH:mm")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _vm.refundPayCardInfo.paymentType !==
                                      `debit`
                                        ? _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "12",
                                                sm: "6",
                                                md: "4",
                                              },
                                            },
                                            [
                                              _c("strong", [
                                                _vm._v("İade Edilen Tutar:"),
                                              ]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.refundPayCardInfo
                                                      .refundPrice
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.refundPayCardInfo.paymentType ==
                                      `debit`
                                        ? _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "12",
                                                sm: "6",
                                                md: "4",
                                              },
                                            },
                                            [
                                              _c("strong", [
                                                _vm._v(
                                                  "İadesi Talep Edilen Tutar:"
                                                ),
                                              ]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.refundPayCardInfo
                                                      .reqPrice
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "6",
                                            md: "4",
                                          },
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v("İşlemi Gerçekleştiren:"),
                                          ]),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.refundPayCardInfo.personİnfo
                                              ) +
                                              _vm._s("/") +
                                              " " +
                                              _vm._s(
                                                _vm.refundPayCardInfo
                                                  .departmanRole
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.reservationData?.payments?.length > 0
                        ? _c(
                            "v-card",
                            { staticClass: "mb-5", attrs: { elevation: "10" } },
                            [
                              _c(
                                "v-toolbar",
                                {
                                  attrs: {
                                    height: "40",
                                    dense: "",
                                    dark: "",
                                    color: "grey darken-1",
                                    elevation: "0",
                                  },
                                },
                                [
                                  _c(
                                    "v-toolbar-title",
                                    { staticStyle: { "font-size": "14px" } },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          " " + _vm._s(_vm.$t("payments")) + " "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("v-simple-table", [
                                _c("thead", [
                                  _c("tr", [
                                    _c("th", [_vm._v("Order ID")]),
                                    _c("th", [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("type")) + " "
                                      ),
                                    ]),
                                    _c("th", [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("channel")) + " "
                                      ),
                                    ]),
                                    _c("th", [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("date")) + " "
                                      ),
                                    ]),
                                    _c("th", [_vm._v("ERP")]),
                                    _c("th", { staticClass: "text-right" }, [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("amount")) + " "
                                      ),
                                    ]),
                                    _vm.reservationData.consensus.status !=
                                    "completed"
                                      ? _c(
                                          "th",
                                          { staticClass: "text-right" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("early_return")) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]),
                                _c(
                                  "tbody",
                                  _vm._l(
                                    _vm.reservationData?.payments,
                                    function (payment) {
                                      return _c("tr", { key: payment._id }, [
                                        _c("td", [
                                          _vm._v(_vm._s(payment?.orderId)),
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                payment?.paymentType ==
                                                  "payment"
                                                  ? _vm.$t("collection")
                                                  : _vm.$t("early_return")
                                              ) +
                                              " "
                                          ),
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                payment?.channel == "pos"
                                                  ? _vm.$t("virtual_pos")
                                                  : payment?.channel
                                              ) +
                                              " "
                                          ),
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm
                                                  .moment(payment?.created)
                                                  .local()
                                                  .format("DD-MM-YYYY HH:mm")
                                              ) +
                                              " "
                                          ),
                                        ]),
                                        _c(
                                          "td",
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  payment?.erpStatus ==
                                                    "pending"
                                                    ? _vm.$t("pending")
                                                    : payment?.erpStatus == "ok"
                                                    ? _vm.$t("transferred")
                                                    : _vm.$t("error_t")
                                                ) +
                                                " "
                                            ),
                                            payment.erpError
                                              ? _c("font-awesome-icon", {
                                                  directives: [
                                                    {
                                                      name: "c-tooltip",
                                                      rawName: "v-c-tooltip",
                                                      value: {
                                                        content:
                                                          payment?.erpError,
                                                        placement: "top",
                                                      },
                                                      expression:
                                                        "{\n                      content: payment?.erpError,\n                      placement: 'top',\n                    }",
                                                    },
                                                  ],
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    icon: [
                                                      "fas",
                                                      "info-circle",
                                                    ],
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        payment?.totalAmount
                                          ? _c(
                                              "td",
                                              {
                                                staticClass: "text-right",
                                                staticStyle: {
                                                  "font-weight": "bolder",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      payment?.totalAmount.toLocaleString(
                                                        "tr-TR",
                                                        {
                                                          style: "currency",
                                                          currency: "TRY",
                                                        }
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "td",
                                          [
                                            _c("RoleProvider", {
                                              attrs: {
                                                slug: "RESERVATION_REFUND",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({
                                                      canItPass,
                                                    }) {
                                                      return _c(
                                                        "div",
                                                        {},
                                                        [
                                                          payment.paymentType ==
                                                            "payment" &&
                                                          !_vm.isPartner &&
                                                          _vm.reservationData
                                                            .consensus.status !=
                                                            "completed" &&
                                                          canItPass
                                                            ? _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    small: "",
                                                                    block: "",
                                                                    color:
                                                                      "error",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.cacelBtnClick(
                                                                          "single"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "early_return_btn"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      )
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                      ])
                                    }
                                  ),
                                  0
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.reservationData.status != "pending" &&
                      _vm.reservationData.paymentType != "debit"
                        ? _c(
                            "v-card",
                            { staticClass: "mb-5", attrs: { elevation: "10" } },
                            [
                              _c(
                                "v-toolbar",
                                {
                                  attrs: {
                                    height: "40",
                                    dense: "",
                                    dark: "",
                                    color: "grey darken-1",
                                    elevation: "0",
                                  },
                                },
                                [
                                  _c(
                                    "v-toolbar-title",
                                    { staticStyle: { "font-size": "14px" } },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("pos_transactions")) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("v-simple-table", { attrs: { dense: "" } }, [
                                _c("thead", [
                                  _c("tr", [
                                    _c("th", [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("process_t")) + " "
                                      ),
                                    ]),
                                    _c("th", [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("date")) + " "
                                      ),
                                    ]),
                                    _c("th", [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("message")) + " "
                                      ),
                                    ]),
                                    _c("th", [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("card")) + " "
                                      ),
                                    ]),
                                    _c("th", [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("amount")) + " "
                                      ),
                                    ]),
                                  ]),
                                ]),
                                _c(
                                  "tbody",
                                  _vm._l(
                                    _vm.reservationData.bankTransactions,
                                    function (bankitem) {
                                      return _c(
                                        "tr",
                                        {
                                          key: bankitem._id,
                                          class:
                                            bankitem.type === "paymentError" ||
                                            bankitem.type === "paymentFail"
                                              ? "text-danger"
                                              : "text-success",
                                        },
                                        [
                                          _c("td", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  bankitem.langName
                                                    ? bankitem.langName.TR ||
                                                        bankitem.langName.tr
                                                    : bankitem.type
                                                ) +
                                                " "
                                            ),
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("moment")(
                                                    bankitem.createdAt,
                                                    "add",
                                                    "3 hours",
                                                    "DD-MM-YYYY HH:mm:ss"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(bankitem.code) +
                                                " " +
                                                _vm._s(bankitem.message)
                                            ),
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(bankitem.data.CardNumber)
                                            ),
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  bankitem.data.TotalAmount &&
                                                    bankitem.data.TotalAmount +
                                                      " TL"
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-card",
                        { staticClass: "mb-5", attrs: { elevation: "10" } },
                        [
                          _c(
                            "v-toolbar",
                            {
                              attrs: {
                                height: "40",
                                dense: "",
                                dark: "",
                                color: "grey darken-1",
                                elevation: "0",
                              },
                            },
                            [
                              _c(
                                "v-toolbar-title",
                                { staticStyle: { "font-size": "14px" } },
                                [
                                  _c("strong", [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("agent_note")) + " "
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("AgentNote", {
                            attrs: {
                              _id: _vm.reservationData._id,
                              slug: "reservations",
                              total: _vm.agentNotesTotal,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.reservationData.status == "completed"
                        ? _c(
                            "v-card",
                            { staticClass: "mb-5", attrs: { elevation: "10" } },
                            [
                              _c(
                                "v-toolbar",
                                {
                                  attrs: {
                                    height: "40",
                                    dense: "",
                                    dark: "",
                                    color: "grey darken-1",
                                    elevation: "0",
                                  },
                                },
                                [
                                  _c(
                                    "v-toolbar-title",
                                    { staticStyle: { "font-size": "14px" } },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("reservation_survey")
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("Comment", {
                                attrs: {
                                  _id: _vm.reservationData._id,
                                  reservationCode:
                                    _vm.reservationData.reservationCode,
                                  slug: "reservations",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c("gogo-loading"),
      _c(
        "CModal",
        {
          attrs: {
            "close-on-backdrop": "",
            title: `${_vm.reservationData.reservationCode}  ${_vm.$t(
              "rejection_desc3"
            )}`,
            size: "lg",
            show: _vm.reservastionCancelModal,
          },
          on: {
            "update:show": function ($event) {
              _vm.reservastionCancelModal = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "CButton",
                    {
                      attrs: { disabled: _vm.statusOfCancellation },
                      on: {
                        click: function ($event) {
                          _vm.reservastionCancelModal = false
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("leave")))]
                  ),
                  _c(
                    "CButton",
                    {
                      attrs: {
                        color: "danger",
                        disabled: _vm.statusOfCancellation,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.reservationStatusUpdate("cancelled")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("send")) + " ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "v-row",
            [
              _c("v-col", [
                _c("h3", [
                  _vm._v(" " + _vm._s(_vm.$t("rejection_desc")) + " "),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "form-group", attrs: { form: "" } },
            [
              _c(
                "v-col",
                [
                  _vm._l(_vm.reasonsOfCancel, function (option) {
                    return _c("CInputRadio", {
                      key: option._id,
                      attrs: {
                        name: "reasonCancel",
                        label: option.question,
                        value: option.question,
                      },
                      on: {
                        "update:checked": () =>
                          (_vm.reasonCancel = option.question),
                      },
                    })
                  }),
                  _c("CInputRadio", {
                    attrs: { name: "reasonCancel", label: _vm.$t("other") },
                    on: { "update:checked": () => (_vm.reasonCancel = 1) },
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _vm.reasonCancel == 1
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("CTextarea", {
                        attrs: { placeholder: _vm.$t("leave") },
                        model: {
                          value: _vm.otherReasonCancel,
                          callback: function ($$v) {
                            _vm.otherReasonCancel = $$v
                          },
                          expression: "otherReasonCancel",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "CModal",
        {
          attrs: {
            "close-on-backdrop": "",
            title:
              `${_vm.reservationData.reservationCode} ` +
              _vm.$t("early_return_info2"),
            size: "lg",
            show: _vm.earlyReturnModal,
          },
          on: {
            "update:show": function ($event) {
              _vm.earlyReturnModal = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "CButton",
                    {
                      on: {
                        click: function ($event) {
                          _vm.earlyReturnModal = false
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("leave")) + " ")]
                  ),
                  _c(
                    "CButton",
                    {
                      attrs: { color: "danger" },
                      on: {
                        click: function ($event) {
                          return _vm.earlyReturnRequest()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("send")) + " ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "v-row",
            [
              _c("v-col", [
                _c("h3", [_vm._v(_vm._s(_vm.$t("early_return_info3")))]),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "form-group", attrs: { form: "" } },
            [
              _c(
                "v-col",
                [
                  _vm._l(_vm.reasonsOfEarlyReturn, function (option) {
                    return _c("CInputRadio", {
                      key: option._id,
                      attrs: {
                        name: "reasonCancel",
                        label: option.question,
                        value: option.question,
                      },
                      on: {
                        "update:checked": () =>
                          (_vm.reasonEarlyReturn = option.question),
                      },
                    })
                  }),
                  _c("CInputRadio", {
                    attrs: { name: "reasonCancel", label: _vm.$t("other") },
                    on: {
                      "update:checked": () => (_vm.openEarlyTextarea = true),
                    },
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _vm.openEarlyTextarea
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("CTextarea", {
                        attrs: { placeholder: _vm.$t("your_explanation") },
                        model: {
                          value: _vm.reasonEarlyReturn,
                          callback: function ($$v) {
                            _vm.reasonEarlyReturn = $$v
                          },
                          expression: "reasonEarlyReturn",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.reservationData.carDeliveryReturn
        ? _c(
            "CModal",
            {
              attrs: {
                "close-on-backdrop": false,
                size: "l",
                show: _vm.formEarlyReturnRegistrationModal,
                title: "Erken İade Tutar ve Tarih Giriniz",
              },
              on: {
                "update:show": function ($event) {
                  _vm.formEarlyReturnRegistrationModal = $event
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "footer",
                    fn: function () {
                      return [
                        _c(
                          "CButton",
                          {
                            attrs: { disabled: _vm.statusOfEarlyPayment },
                            on: {
                              click: function ($event) {
                                _vm.formEarlyReturnRegistrationModal = false
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("leave")) + " ")]
                        ),
                        _c(
                          "CButton",
                          {
                            staticStyle: { color: "white" },
                            attrs: {
                              color: "danger",
                              disabled: _vm.statusOfEarlyPayment,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.earlyReturnRegistration()
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("send")) + " ")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                310914370
              ),
            },
            [
              _c(
                "v-col",
                [
                  _c("label", { staticStyle: { "font-weight": "bold" } }, [
                    _vm._v(" " + _vm._s(_vm.$t("returned_date")) + " "),
                  ]),
                  _c("br"),
                  _c(
                    "small",
                    { staticStyle: { color: "red", "font-weight": "200" } },
                    [_vm._v(_vm._s(_vm.$t("early_return_description")))]
                  ),
                  _c("VueCtkDateTimePicker", {
                    attrs: {
                      "no-shortcuts": "",
                      "no-header": "",
                      minDate:
                        _vm.reservationData.carDeliveryReturn.delivery.datetime,
                      maxDate:
                        _vm.reservationData.carDeliveryReturn.return.datetime,
                      format: "YYYY-MM-DD HH:mm",
                      formatted: "Do MMM YYYY ddd HH:mm",
                      locale: _vm.locale,
                      label: _vm.$t("returned_date"),
                    },
                    model: {
                      value: _vm.earlyReturnDate,
                      callback: function ($$v) {
                        _vm.earlyReturnDate = $$v
                      },
                      expression: "earlyReturnDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c("CInput", {
                    staticClass: "font-weight-bold",
                    attrs: {
                      label: _vm.$t("refund_payment_amount"),
                      type: "number",
                    },
                    model: {
                      value: _vm.earlyReturnPaymentAmount,
                      callback: function ($$v) {
                        _vm.earlyReturnPaymentAmount = _vm._n($$v)
                      },
                      expression: "earlyReturnPaymentAmount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { staticClass: "col-6" }, [
                        _c("strong", [_vm._v("İade Tarihi:")]),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.earlyReturnDate
                                ? _vm
                                    .moment(_vm.earlyReturnDate)
                                    .local()
                                    .format("D MMMM YYYY")
                                : "-"
                            ) +
                            " "
                        ),
                      ]),
                      _c("v-col", { staticClass: "col-6" }, [
                        _c("strong", [_vm._v("İade Ücreti:")]),
                        _vm._v(" " + _vm._s(_vm.earlyReturnPaymentAmount)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "CModal",
        {
          staticClass: "vendorModalAddUpdateForm modalNoBorder",
          attrs: {
            size: "lg",
            title: "Ödeme",
            show: _vm.paymentModalShow,
            "close-on-backdrop": false,
          },
          on: {
            "update:show": function ($event) {
              _vm.paymentModalShow = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "CButton",
                    {
                      attrs: {
                        disabled: _vm.createPaymentBtnLoading,
                        color: "success",
                      },
                      on: { click: _vm.makeNoneSecurePayment },
                    },
                    [
                      _c("font-awesome-icon", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.createPaymentBtnLoading,
                            expression: "createPaymentBtnLoading",
                          },
                        ],
                        staticClass: "spin mr-2",
                        attrs: { icon: ["fas", "circle-notch"], size: "lg" },
                      }),
                      _vm._v(" " + _vm._s(_vm.$t("complete_payment")) + " "),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "CCard",
            { staticClass: "p-0 m-0 cardShadow" },
            [
              _c(
                "CCardBody",
                [
                  _c(
                    "validation-observer",
                    { ref: "refValidationNoneSecure" },
                    [
                      _c(
                        "CContainer",
                        { staticClass: "p-4 m-0" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("CInput", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: "#### #### #### ####",
                                        expression: "'#### #### #### ####'",
                                      },
                                    ],
                                    ref: "cardNumber",
                                    attrs: {
                                      placeholder: _vm.$t("card_number2"),
                                      label: _vm.$t("card_number2"),
                                      value:
                                        _vm.paymentNoneSecureForm.cardNumber,
                                    },
                                    model: {
                                      value:
                                        _vm.paymentNoneSecureForm.cardNumber,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.paymentNoneSecureForm,
                                          "cardNumber",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "paymentNoneSecureForm.cardNumber",
                                    },
                                  }),
                                  _c("CInput", {
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "owner_of_the_card_number"
                                      ),
                                      label: _vm.$t("owner_of_the_card_number"),
                                      value:
                                        _vm.paymentNoneSecureForm.cardOwner,
                                    },
                                    model: {
                                      value:
                                        _vm.paymentNoneSecureForm.cardOwner,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.paymentNoneSecureForm,
                                          "cardOwner",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "paymentNoneSecureForm.cardOwner",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("CInput", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: `##/##`,
                                        expression: "`##/##`",
                                      },
                                    ],
                                    attrs: {
                                      placeholder: "AA/YY",
                                      label:
                                        _vm.$t("month") + "/" + _vm.$t("year"),
                                    },
                                    model: {
                                      value: _vm.paymentNoneSecureForm.expiry,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.paymentNoneSecureForm,
                                          "expiry",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "paymentNoneSecureForm.expiry",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                [
                                  _c("CInput", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: `###`,
                                        expression: "`###`",
                                      },
                                    ],
                                    attrs: {
                                      placeholder: _vm.$t("secure_code"),
                                      label: _vm.$t("secure_code"),
                                    },
                                    model: {
                                      value: _vm.paymentNoneSecureForm.cvv,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.paymentNoneSecureForm,
                                          "cvv",
                                          $$v
                                        )
                                      },
                                      expression: "paymentNoneSecureForm.cvv",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "CModal",
        {
          attrs: {
            "close-on-backdrop": "",
            title: _vm.$t("reservation_settings"),
            size: "lg",
            show: _vm.reservationSetting.modalShow,
          },
          on: {
            "update:show": function ($event) {
              return _vm.$set(_vm.reservationSetting, "modalShow", $event)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "CButton",
                    {
                      on: {
                        click: function ($event) {
                          _vm.reservationSetting.modalShow = false
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("leave")) + " ")]
                  ),
                  _c(
                    "CButton",
                    {
                      attrs: { disabled: _vm.reservationSetting.modalLoading },
                      on: {
                        click: function ($event) {
                          return _vm.reservationUpdate()
                        },
                      },
                    },
                    [
                      _c("font-awesome-icon", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.reservationSetting.modalLoading,
                            expression: "reservationSetting.modalLoading",
                          },
                        ],
                        staticClass: "spin mr-2",
                        attrs: { icon: ["fas", "circle-notch"], size: "lg" },
                      }),
                      _vm._v(" " + _vm._s(_vm.$t("update")) + " "),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "v-col",
            { staticClass: "bil__type", attrs: { col: "12", lg: "6" } },
            [
              _c("CSelect", {
                attrs: {
                  nam: "payment-type",
                  value: _vm.reservationData.paymentType,
                  options:
                    _vm.reservationSetting.typesOfPaymentAvailableOptions,
                  label: _vm.$t("payment_type"),
                  placeholder: _vm.$t("select_payment_type"),
                },
                on: {
                  "update:value": function ($event) {
                    return _vm.$set(_vm.reservationData, "paymentType", $event)
                  },
                },
              }),
            ],
            1
          ),
          !_vm.isPartner
            ? _c(
                "v-col",
                { staticClass: "bil__type", attrs: { col: "12", lg: "6" } },
                [
                  _c("CSelect", {
                    attrs: {
                      value: _vm.reservationData.spendingObjection,
                      options: _vm.reservationSetting.spendingObjectionOptions,
                      label: _vm.$t("spending_objection"),
                      placeholder: _vm.$t("select_spending_objection"),
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(
                          _vm.reservationData,
                          "spendingObjection",
                          $event
                        )
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.reservationData.carDeliveryReturn
        ? _c(
            "CModal",
            {
              attrs: {
                "close-on-backdrop": "",
                title:
                  `${_vm.reservationData.reservationCode} ` +
                  "Numaralı Rezervasyona Ücret İadesi Yapıyorsunuz.",
                size: "lg",
                show: _vm.refundPaymentModel,
              },
              on: {
                "update:show": function ($event) {
                  _vm.refundPaymentModel = $event
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "footer",
                    fn: function () {
                      return [
                        _c(
                          "CButton",
                          {
                            on: {
                              click: function ($event) {
                                ;(_vm.refundPaymentModel = false),
                                  (_vm.partialRefundPayment = 0)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("leave")) + " ")]
                        ),
                        _vm.partialRefundPayment <=
                        _vm.reservationData.prices.totalPrice.TRY
                          ? _c(
                              "CButton",
                              {
                                attrs: { color: "secondary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.refundPayment()
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("send")) + " ")]
                            )
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                2370121317
              ),
            },
            [
              _c(
                "v-tabs",
                {
                  attrs: { "show-arrows": false },
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _c(
                    "v-tab",
                    {
                      on: {
                        click: function ($event) {
                          _vm.typeOfRefundPayment = "PARTIAL"
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("partial_refund_fee_payment")) + " "
                      ),
                    ]
                  ),
                  _c(
                    "v-tab",
                    {
                      on: {
                        click: function ($event) {
                          _vm.typeOfRefundPayment = "TOTAL"
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("full_refund_fee_payment")) + " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-tabs-items",
                {
                  staticClass: "mt-4",
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _c(
                    "v-tab-item",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("CInput", {
                                staticClass: "font-weight-bold",
                                attrs: {
                                  label: _vm.$t("refund_payment_amount"),
                                  type: "number",
                                },
                                model: {
                                  value: _vm.partialRefundPayment,
                                  callback: function ($$v) {
                                    _vm.partialRefundPayment = _vm._n($$v)
                                  },
                                  expression: "partialRefundPayment",
                                },
                              }),
                              !(
                                _vm.partialRefundPayment <=
                                _vm.reservationData.prices.totalPrice.TRY
                              )
                                ? _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        color: "red",
                                        "font-weight": "bold",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "Kısmi Ücret Tutarı Tam ücret Tutarından Fazla Olamaz."
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("CInput", {
                                staticClass: "font-weight-bold",
                                attrs: {
                                  label: _vm.$t("refund_payment_amount"),
                                  readonly: true,
                                  type: "number",
                                },
                                model: {
                                  value:
                                    _vm.reservationData.prices.totalPrice.TRY,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.reservationData.prices.totalPrice,
                                      "TRY",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression:
                                    "reservationData.prices.totalPrice.TRY",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "CModal",
        {
          attrs: {
            "close-on-backdrop": "",
            title:
              `${_vm.reservationData.reservationCode} ` +
              "Numaralı Rezervasyona NoShow Ücret İadesi Yapıyorsunuz.",
            size: "lg",
            show: _vm.noShowRefundPaymentModel,
          },
          on: {
            "update:show": function ($event) {
              _vm.noShowRefundPaymentModel = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "CButton",
                    {
                      on: {
                        click: function ($event) {
                          ;(_vm.noShowRefundPaymentModel = false),
                            (_vm.partialNoShowRefundPayment = 0)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("leave")) + " ")]
                  ),
                  _vm.partialNoShowRefundPayment <=
                  _vm.reservationData.prices.totalPrice.TRY
                    ? _c(
                        "CButton",
                        {
                          attrs: { color: "secondary" },
                          on: {
                            click: function ($event) {
                              return _vm.noShowRefundPayment()
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("send")) + " ")]
                      )
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "v-tabs",
            {
              attrs: { "show-arrows": false },
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            [
              _c(
                "v-tab",
                {
                  on: {
                    click: function ($event) {
                      _vm.typeOfNoShowRefundPayment = "PARTIAL"
                    },
                  },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("partial_refund_fee_payment")) + " "
                  ),
                ]
              ),
              _c(
                "v-tab",
                {
                  on: {
                    click: function ($event) {
                      _vm.typeOfNoShowRefundPayment = "TOTAL"
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("full_refund_fee_payment")) + " ")]
              ),
            ],
            1
          ),
          _c(
            "v-tabs-items",
            {
              staticClass: "mt-4",
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            [
              _c(
                "v-tab-item",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("CInput", {
                            staticClass: "font-weight-bold",
                            attrs: {
                              label: _vm.$t("refund_payment_amount"),
                              type: "number",
                              disabled:
                                _vm.typeOfNoShowRefundPayment == `justNoShow`,
                            },
                            model: {
                              value: _vm.partialNoShowRefundPayment,
                              callback: function ($$v) {
                                _vm.partialNoShowRefundPayment = _vm._n($$v)
                              },
                              expression: "partialNoShowRefundPayment",
                            },
                          }),
                          !(
                            _vm.partialNoShowRefundPayment <=
                            _vm.reservationData.prices.totalPrice.TRY
                          )
                            ? _c(
                                "span",
                                {
                                  staticStyle: {
                                    color: "red",
                                    "font-weight": "bold",
                                  },
                                },
                                [
                                  _vm._v(
                                    "Kısmi Ücret Tutarı Tam ücret Tutarından Fazla Olamaz."
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-tab-item",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("CInput", {
                            staticClass: "font-weight-bold",
                            attrs: {
                              label: _vm.$t("refund_payment_amount"),
                              readonly: true,
                              type: "number",
                              disabled:
                                _vm.typeOfNoShowRefundPayment == `justNoShow`,
                            },
                            model: {
                              value: _vm.reservationData.prices.totalPrice.TRY,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.reservationData.prices.totalPrice,
                                  "TRY",
                                  _vm._n($$v)
                                )
                              },
                              expression:
                                "reservationData.prices.totalPrice.TRY",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-checkbox", {
            attrs: {
              label: "Sadece NoShow",
              color: "red",
              value: "justNoShow",
            },
            model: {
              value: _vm.typeOfNoShowRefundPayment,
              callback: function ($$v) {
                _vm.typeOfNoShowRefundPayment = $$v
              },
              expression: "typeOfNoShowRefundPayment",
            },
          }),
          _vm.typeOfNoShowRefundPayment == `justNoShow`
            ? _c(
                "span",
                { staticStyle: { color: "red", "font-weight": "bold" } },
                [
                  _vm._v(
                    "Rezervasyonun durumu Noshow olarak değiştirilecek ve herhangi bir iade ödemesi yapılmayacaktır."
                  ),
                  _c("br"),
                  _vm._v(" Müşteriye mail veya sms gönderimi yapılmayacaktır."),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }